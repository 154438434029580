import * as types from './mutation-types'

export default {
  [types.BOOTSTRAP_ITEMS](state, items) {
    state.journal = items
  },

  [types.SET_TOTAL_ITEMS](state, totalJournal) {
    state.totalJournal = totalJournal
  },

  [types.ADD_ITEM](state, data) {
    state.journal.push(data.item)
  },
}
