import * as types from './mutation-types'

export const fetchJournal = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/journal`, { params }).then((response) => {
      commit(types.BOOTSTRAP_ITEMS, response.data.journal.data)
      commit(types.SET_TOTAL_ITEMS, response.data.journal.total)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const addJournal = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/journal', data).then((response) => {
      commit(types.ADD_ITEM, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
