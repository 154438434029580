export const currencies = (state) => state.currencies
export const defaultCurrency = (state) => state.defaultCurrency
export const defaultCurrencyForInput = (state) => {
  if (state.defaultCurrency) {
    return {
      decimal: state.defaultCurrency.decimal_separator,
      thousands: state.defaultCurrency.thousand_separator,
      suffix: '  '+state.defaultCurrency.symbol,
      precision: 0,
      masked: false
    }
  }

  return {
    decimal: ',',
    thousands: '.',
    suffix: ' CFA',
    precision: 0,
    masked: false
  }
}
