import * as types from './mutation-types'

export const loadMecef = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/settings/emecef`).then((response) => {
      commit(types.SET_MECEF, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const editMecef = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put('/api/settings/emecef', data).then((response) => {
      commit(types.UPDATE_MECEF, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
