var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("supply.add")) + "\n    ")
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/dashboard" },
                slot: "item-title"
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/supply" },
                slot: "item-title"
              },
              [_vm._v(_vm._s(_vm.$tc("supply.supply", 2)))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(" " + _vm._s(_vm.$t("supply.add")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitItem($event)
                }
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-12" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("supply.product")))
                      ]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *")
                      ]),
                      _vm._v(" "),
                      _c("base-select", {
                        attrs: {
                          options: _vm.productItems,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("supply.select_a_product"),
                          label: "name"
                        },
                        model: {
                          value: _vm.formData.item,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "item", $$v)
                          },
                          expression: "formData.item"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.item.$error
                        ? _c("div", [
                            !_vm.$v.formData.item.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-group col-sm-6" }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("supply.purchase_price")))
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "base-input" },
                      [
                        _c(
                          "money",
                          _vm._b(
                            {
                              staticClass: "input-field",
                              class: { invalid: _vm.$v.formData.price.$error },
                              model: {
                                value: _vm.price,
                                callback: function($$v) {
                                  _vm.price = $$v
                                },
                                expression: "price"
                              }
                            },
                            "money",
                            _vm.defaultCurrencyForInput,
                            false
                          )
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$v.formData.price.$error
                      ? _c("div", [
                          !_vm.$v.formData.price.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.required")) +
                                    "\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.price.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.price_maxlength"))
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.price.minValue
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.price_minvalue"))
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("supply.quantity")))]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *")
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          invalid: _vm.$v.formData.quantity.$error,
                          focus: "",
                          type: "number",
                          name: "quantity"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.formData.quantity.$touch()
                          }
                        },
                        model: {
                          value: _vm.formData.quantity,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              "quantity",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.quantity"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.quantity.$error
                        ? _c("div", [
                            !_vm.$v.formData.quantity.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "collapse-button",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            icon: "save",
                            color: "theme",
                            type: "submit"
                          }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("items.save_item")) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }