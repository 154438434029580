<template>
  <div class="sidebar-left">
    <div class="sidebar-body scroll-pane">
      <div class="side-nav">
        <div v-for="(menuItems, index) in menu" :key="index" class="menu-group">
          <router-link
            v-for="(item, index1) in menuItems"
            :key="index1"
            :to="item.route"
            class="menu-item"
            @click.native="Toggle"
          >
            <font-awesome-icon :icon="item.icon" class="icon menu-icon" />
            <span class="ml-3 menu-text">{{ $t(item.title) }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  data() {
    return {
      sidebar: 'sidebar',
      menuAdmin: [
        [
          {
            title: 'navigation.dashboard',
            icon: 'tachometer-alt',
            route: '/admin/dashboard',
          },
          {
            title: 'navigation.customers',
            icon: 'user',
            route: '/admin/customers',
          },
          {
            title: 'navigation.items',
            icon: 'star',
            route: '/admin/items',
          },
          {
            title: 'navigation.supply',
            icon: 'signal',
            route: '/admin/supply',
          },
        ],

        [
          {
            title: 'navigation.estimates',
            icon: 'file',
            route: '/admin/estimates',
          },
          {
            title: 'navigation.invoices',
            icon: 'file-alt',
            route: '/admin/invoices',
          },
          // {
          //   title: 'navigation.payments',
          //   icon: 'credit-card',
          //   route: '/admin/payments',
          // },
          {
            title: 'navigation.expenses',
            icon: 'space-shuttle',
            route: '/admin/expenses',
          },
          {
            title: 'navigation.canceledInvoices',
            icon: 'file',
            route: '/admin/canceled-invoices',
          },
        ],

        [
          {
            title: 'navigation.reports',
            icon: 'signal',
            route: '/admin/reports',
          },
          {
            title: 'navigation.settings',
            icon: 'cog',
            route: '/admin/settings',
          },
          {
            title: 'navigation.journal',
            icon: 'file-alt',
            route: '/admin/journal',
          },
          {
            title: 'navigation.users',
            icon: 'user',
            route: '/admin/users',
          },
        ],
      ],
      menuGerant: [
        [
          {
            title: 'navigation.dashboard',
            icon: 'tachometer-alt',
            route: '/admin/dashboard',
          },
          {
            title: 'navigation.customers',
            icon: 'user',
            route: '/admin/customers',
          },
          {
            title: 'navigation.items',
            icon: 'star',
            route: '/admin/items',
          },
          // {
          //   title: 'navigation.supply',
          //   icon: 'signal',
          //   route: '/admin/supply',
          // },
        ]
      ],
      menuCaisse: [
        [
          {
            title: 'navigation.dashboard',
            icon: 'tachometer-alt',
            route: '/admin/dashboard',
          },
          {
            title: 'navigation.customers',
            icon: 'user',
            route: '/admin/customers',
          },
        ],

        [
          {
            title: 'navigation.estimates',
            icon: 'file',
            route: '/admin/estimates',
          },
          {
            title: 'navigation.invoices',
            icon: 'file-alt',
            route: '/admin/invoices',
          },
          // {
          //   title: 'navigation.expenses',
          //   icon: 'space-shuttle',
          //   route: '/admin/expenses',
          // },
          {
            title: 'navigation.canceledInvoices',
            icon: 'file',
            route: '/admin/canceled-invoices',
          },
        ],
      ],
    }
  },
  computed: {
    menu() {
      // Admin (A) : 7fc56270e7a70fa81a5935b72eacbe29
      // Gerant (G) : dfcf28d0734569a6a693bc8194de62bf
      // Caisse (C) : 0d61f8370cad1d412f80b84d143e1257
      // return this.menuAdmin;
      var role = this.$store.getters['auth/userRole']
      if (role === 'admin') {
        return this.menuAdmin
      } else if (role === 'controlleur') {
        return this.menuGerant
      } else if (role === 'caissière') {
        return this.menuCaisse
      }
    },
  },
  created() {
    // console.log(this.$store.getters['auth/userRole'])
  },

  methods: {
    Toggle() {
      this.$utils.toggleSidebar()
    },
  },
}
</script>
