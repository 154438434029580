import Ls from '@/services/ls'
import * as types from './mutation-types'
import * as userTypes from '../user/mutation-types'
import * as rootTypes from '../../mutation-types'
import router from '@/router.js'

export const login = ({ commit, dispatch, state }, data) => {
  let loginData = {
    username: data.email,
    password: data.password
  }
  return new Promise((resolve, reject) => {
    axios.post('/api/auth/login', loginData).then((response) => {
      let token = response.data.access_token
      Ls.set('auth.token', token)

      if (response.data.user) {
        Ls.set('user', response.data.user.id)
        Ls.set('userName', response.data.user.name)
        if (response.data.user.role === "admin") {
          Ls.set('vue-dnd-cache', '7fc56270e7a70fa81a5935b72eacbe29')
        }
        else if (response.data.user.role === "controlleur") {
          Ls.set('vue-dnd-cache', 'dfcf28d0734569a6a693bc8194de62bf')
        }
        else if (response.data.user.role === "caissière") {
          Ls.set('vue-dnd-cache', '0d61f8370cad1d412f80b84d143e1257')
        }
      }

      commit('user/' + userTypes.RESET_CURRENT_USER, null, { root: true })
      commit(rootTypes.UPDATE_APP_LOADING_STATUS, false, { root: true })

      commit(types.AUTH_SUCCESS, token)
      window.toastr['success']('Login Successful')
      resolve(response)
    }).catch(err => {
      commit(types.AUTH_ERROR, err.response)
      Ls.remove('auth.token')
      Ls.remove('vue-dnd-cache')
      Ls.remove('user')
      reject(err)
    })
  })
}

export const refreshToken = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    let data = {
      token: Ls.get('auth.token')
    }
    console.log('REFRESH ACTION')
    axios.post('/api/auth/refresh_token', data).then((response) => {
      let token = response.data.data.token
      Ls.set('auth.token', token)
      commit(types.REFRESH_SUCCESS, token)
      resolve(response)
    }).catch(err => {
      reject(err)
    })
  })
}

export const logout = ({ commit, dispatch, state }, noRequest = false) => {
  if (noRequest) {
    commit(types.AUTH_LOGOUT)
    Ls.remove('vue-dnd-cache')
    Ls.remove('auth.token')
    Ls.remove('user')
    router.push('/login')
    return true
  }

  return new Promise((resolve, reject) => {
    axios.get('/api/auth/logout').then((response) => {
      commit(types.AUTH_LOGOUT)
      Ls.remove('auth.token')
      Ls.remove('vue-dnd-cache')
      Ls.remove('user')
      router.push('/login')
      window.toastr['success']('Logged out!', 'Success')
    }).catch(err => {
      reject(err)
      commit(types.AUTH_LOGOUT)
      Ls.remove('auth.token')
      Ls.remove('vue-dnd-cache')
      Ls.remove('user')
      router.push('/login')
    })
  })
}

export const loginOnBoardingUser = ({ commit, dispatch, state }, token) => {
  commit(types.AUTH_SUCCESS, token)
}
