export const isAuthenticated = (state) => !!state.token
export const authStatus = (state) => state.status
export const userRole = (state) => {
  var role = localStorage.getItem('vue-dnd-cache')
  if (role === '7fc56270e7a70fa81a5935b72eacbe29') {
    return "admin"
  } else if (role === 'dfcf28d0734569a6a693bc8194de62bf') {
    return "controlleur"
  } else if (role === '0d61f8370cad1d412f80b84d143e1257') {
    return "caissière"
  }
}
