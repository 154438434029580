<template>
  <div v-if="estimate" class="main-content estimate-view-page">
    <div class="page-header">
      <h3 class="page-title">{{ estimate.estimate_number }}</h3>
      <div class="page-actions row">
        <div class="col-xs-2 mr-3"></div>
        <div class="col-xs-2"></div>

        <base-button
          v-if="estimate.mcf"
          class="inv-button inv-filter-fields-btn"
          color="primary"
          size="medium"
          @click="convertInToInvoice()"
        >
          <font-awesome-icon
            :icon="['fas', 'file']"
            class="dropdown-item-icon"
          />
          &nbsp; Généner Facture
        </base-button>
        <v-dropdown
          :close-on-select="true"
          align="left"
          class="filter-container"
        >
          <a slot="activator" href="#">
            <base-button color="theme">
              <font-awesome-icon icon="ellipsis-h" />
            </base-button>
          </a>
          <v-dropdown-item>
            <div class="dropdown-item" @click="copyPdfUrl()">
              <font-awesome-icon
                :icon="['fas', 'link']"
                class="dropdown-item-icon"
              />
              {{ $t('general.copy_pdf_url') }}
            </div>
            <router-link
              :to="{ path: `/admin/estimates/${$route.params.id}/edit` }"
              class="dropdown-item"
            >
              <font-awesome-icon
                :icon="['fas', 'pencil-alt']"
                class="dropdown-item-icon"
              />
              {{ $t('general.edit') }}
            </router-link>
            <div
              v-if="estimate.mcf"
              class="dropdown-item"
              @click="convertInToInvoice()"
            >
              <font-awesome-icon
                :icon="['fas', 'file']"
                class="dropdown-item-icon"
              />
              Généner Facture
            </div>

            <div
              class="dropdown-item"
              @click="removeEstimate($route.params.id)"
            >
              <font-awesome-icon
                :icon="['fas', 'trash']"
                class="dropdown-item-icon"
              />
              {{ $t('general.delete') }}
            </div>
          </v-dropdown-item>
        </v-dropdown>
      </div>
    </div>
    <div class="estimate-sidebar">
      <base-loader v-if="isSearching" />
      <div v-else class="side-header">
        <base-input
          v-model="searchData.searchText"
          :placeholder="$t('general.search')"
          input-class="inv-search"
          icon="search"
          type="text"
          align-icon="right"
          @input="onSearched()"
        />
        <div class="btn-group ml-3" role="group" aria-label="First group">
          <v-dropdown
            :close-on-select="false"
            align="left"
            class="filter-container"
          >
            <a slot="activator" href="#">
              <base-button
                class="inv-button inv-filter-fields-btn"
                color="default"
                size="medium"
              >
                <font-awesome-icon icon="filter" />
              </base-button>
            </a>
            <div class="filter-title">
              {{ $t('general.sort_by') }}
            </div>
            <div class="filter-items">
              <input
                id="filter_estimate_date"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="estimate_date"
                @change="onSearched"
              />
              <label class="inv-label" for="filter_estimate_date">{{
                $t('reports.estimates.estimate_date')
              }}</label>
            </div>
            <div class="filter-items">
              <input
                id="filter_due_date"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="expiry_date"
                @change="onSearched"
              />
              <label class="inv-label" for="filter_due_date">{{
                $t('estimates.due_date')
              }}</label>
            </div>
            <div class="filter-items">
              <input
                id="filter_estimate_number"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="estimate_number"
                @change="onSearched"
              />
              <label class="inv-label" for="filter_estimate_number">{{
                $t('estimates.estimate_number')
              }}</label>
            </div>
          </v-dropdown>
          <base-button
            v-tooltip.top-center="{ content: getOrderName }"
            class="inv-button inv-filter-sorting-btn"
            color="default"
            size="medium"
            @click="sortData"
          >
            <font-awesome-icon v-if="getOrderBy" icon="sort-amount-up" />
            <font-awesome-icon v-else icon="sort-amount-down" />
          </base-button>
        </div>
      </div>
      <div class="side-content">
        <router-link
          v-for="(estimate, index) in estimates"
          :to="`/admin/estimates/${estimate.id}/view`"
          :key="index"
          class="side-estimate"
        >
          <div class="left">
            <div class="inv-name">{{ estimate.user.name }}</div>
            <div class="inv-number">{{ estimate.estimate_number }}</div>
          </div>
          <div class="right">
            <div
              class="inv-amount"
              v-html="
                $utils.formatMoney(estimate.total, estimate.user.currency)
              "
            />
            <div class="inv-date">{{ estimate.formattedEstimateDate }}</div>
          </div>
        </router-link>
        <p v-if="!estimates.length" class="no-result">
          {{ $t('estimates.no_matching_estimates') }}
        </p>
      </div>
    </div>
    <div class="estimate-view-page-container">
      <iframe :src="`${shareableLink}`" class="frame-style" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Ls from '@/services/ls'
const _ = require('lodash')
import moment from 'moment'

export default {
  data() {
    return {
      id: null,
      count: null,
      estimates: [],
      mecefData: {},
      companyInfos: {},
      estimate: null,
      currency: null,
      searchData: {
        orderBy: null,
        orderByField: null,
        searchText: null,
      },
      status: ['DRAFT', 'SENT', 'VIEWED', 'EXPIRED', 'ACCEPTED', 'REJECTED'],
      isMarkAsSent: false,
      isSendingEmail: false,
      isRequestOnGoing: false,
      isSearching: false,
    }
  },
  computed: {
    getOrderBy() {
      if (
        this.searchData.orderBy === 'asc' ||
        this.searchData.orderBy == null
      ) {
        return true
      }
      return false
    },
    getOrderName() {
      if (this.getOrderBy) {
        return this.$t('general.ascending')
      }
      return this.$t('general.descending')
    },
    shareableLink() {
      return `/estimates/pdf/${this.estimate.unique_hash}`
    },
  },
  watch: {
    $route(to, from) {
      this.loadEstimate()
    },
  },
  created() {
    this.loadEstimates()
    this.loadEstimate()
    this.onSearched = _.debounce(this.onSearched, 500)
  },
  methods: {
    ...mapActions('estimate', [
      'fetchEstimates',
      'getRecord',
      'searchEstimate',
      'markAsSent',
      'sendEmail',
      'deleteEstimate',
      'selectEstimate',
      'fetchViewEstimate',
      'convertToInvoice',
    ]),
    ...mapActions('emecef', ['loadMecef']),
    ...mapActions('companyInfo', ['loadData']),
    async loadEstimates() {
      let response = await this.fetchEstimates()
      if (response.data) {
        this.estimates = response.data.estimates.data
      }
    },
    async loadEstimate() {
      let response = await this.fetchViewEstimate(this.$route.params.id)

      if (response.data) {
        this.estimate = response.data.estimate
      }

      let mecefResponse = await this.loadMecef()
      this.mecefData = mecefResponse.data

      let responseCompany = await this.loadData()
      this.companyInfos.name = responseCompany.data.user.company.name
      this.companyInfos.address_street_1 =
        responseCompany.data.user.addresses[0].address_street_1
      this.companyInfos.ifu = responseCompany.data.user.company.ifu
      this.companyInfos.phone = responseCompany.data.user.addresses[0].phone
    },
    async onSearched() {
      let data = ''
      if (
        this.searchData.searchText !== '' &&
        this.searchData.searchText !== null &&
        this.searchData.searchText !== undefined
      ) {
        data += `search=${this.searchData.searchText}&`
      }

      if (
        this.searchData.orderBy !== null &&
        this.searchData.orderBy !== undefined
      ) {
        data += `orderBy=${this.searchData.orderBy}&`
      }

      if (
        this.searchData.orderByField !== null &&
        this.searchData.orderByField !== undefined
      ) {
        data += `orderByField=${this.searchData.orderByField}`
      }
      this.isSearching = true
      let response = await this.searchEstimate(data)
      this.isSearching = false
      if (response.data) {
        this.estimates = response.data.estimates.data
      }
    },
    sortData() {
      if (this.searchData.orderBy === 'asc') {
        this.searchData.orderBy = 'desc'
        this.onSearched()
        return true
      }
      this.searchData.orderBy = 'asc'
      this.onSearched()
      return true
    },
    async onMarkAsSent() {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('estimates.confirm_mark_as_sent'),
          icon: '/assets/icon/check-circle-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            this.isMarkAsSent = true
            let response = await this.markAsSent({ id: this.estimate.id })
            this.isMarkAsSent = false
            if (response.data) {
              window.toastr['success'](
                this.$tc('estimates.mark_as_sent_successfully')
              )
            }
          }
        })
    },
    async onSendEstimate(id) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('estimates.confirm_send_estimate'),
          icon: '/assets/icon/paper-plane-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            this.isSendingEmail = true
            let response = await this.sendEmail({ id: this.estimate.id })
            this.isSendingEmail = false
            if (response.data.success) {
              window.toastr['success'](
                this.$tc('estimates.send_estimate_successfully')
              )
              return true
            }
            if (response.data.error === 'user_email_does_not_exist') {
              window.toastr['error'](
                this.$tc('estimates.user_email_does_not_exist')
              )
              return true
            }
            window.toastr['error'](this.$tc('estimates.something_went_wrong'))
          }
        })
    },
    copyPdfUrl() {
      let pdfUrl = `${window.location.origin}/estimates/pdf/${this.estimate.unique_hash}`

      let response = this.$utils.copyTextToClipboard(pdfUrl)

      window.toastr['success'](this.$tc('Copied PDF url to clipboard!'))
    },
    convertInToInvoice() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('estimates.confirm_conversion'),
        icon: '/assets/icon/file-alt-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willConvertInToinvoice) => {
        if (willConvertInToinvoice) {
          if (this.mecefData.invoice_type === 'EMECEF') {
            this.submitEmecef(this.estimate.mcf)
          } else {
            this.submitSave(this.estimate.mcf)
          }
          //   let res = await this.convertToInvoice(id)
          //   if (res.data) {
          //     window.toastr['success'](this.$t('estimates.conversion_message'))
          //     this.$router.push(`invoices/${res.data.invoice.id}/edit`)
          //   } else if (res.data.error) {
          //     window.toastr['error'](res.data.message)
          //   }
        }
      })
    },

    submitEmecef(data) {
      this.isLoading = true
      if (this.mecefData.jeton) {
        var myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + this.mecefData.jeton)
        myHeaders.append('content-type', 'application/json')

        var myInit = {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'default',
        }

        fetch(this.mecefData.url + '/info/status', myInit)
          .then((res) => {
            return res.json()
          })
          .then((res) => {
            if (res.status == 401) {
              window.toastr['error']('Accès non autorisé.')
              this.isLoading = false
            } else {
              data = JSON.parse(data)
              let items = data.items
              let invoiceItems = []

              var userId = Ls.get('user')
              var userName = Ls.get('userName')

              items.forEach((element) => {
                invoiceItems.push({
                  name: element.name,
                  price: element.price / 100,
                  quantity: element.quantity / 1,
                  taxGroup: element.group,
                })
              })
              let invoiceRequestDto = {
                ifu: res.ifu,
                type: 'FV',
                aib: data.aib == 1 ? 'A' : data.aib == 5 ? 'B' : null,
                items: invoiceItems,
                operator: {
                  id: userId,
                  name: userName,
                },
                client: {
                  name: this.estimate.user.contact_name,
                  ifu: this.estimate.user.ifu
                    ? this.estimate.user.ifu
                    : '1111222233334',
                },
              }

              // POST REQUEST
              var myInitPost = {
                method: 'POST',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(invoiceRequestDto),
              }

              fetch(this.mecefData.url + '/invoice', myInitPost)
                .then((res) => {
                  return res.json()
                })
                .then((res) => {
                  var myInitPut = {
                    method: 'PUT',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default',
                  }
                  fetch(
                    this.mecefData.url + '/invoice/' + res.uid + '/confirm',
                    myInitPut
                  )
                    .then((res) => {
                      return res.json()
                    })
                    .then((res) => {
                      ///**** Calculer les informations manquantes à EMECEF */
                      let inv = {}

                      inv.taxableTotal = 0
                      inv.nonTaxableTotal = 0
                      inv.totalHT = 0
                      inv.aibAmount = 0
                      inv.vatAmount = 0
                      inv.tpsAmount = 0
                      inv.total = 0

                      invoiceRequestDto.items.forEach((element) => {
                        element.itemTotal = element.price * element.quantity
                        inv.total += parseFloat(element.itemTotal)

                        if (
                          element.taxGroup == 'B' ||
                          element.taxGroup == 'D'
                        ) {
                          element.htAmount = Math.round(
                            element.itemTotal * parseFloat(100 / 118)
                          )
                          inv.taxableTotal += element.htAmount
                          inv.vatAmount +=
                            parseFloat(element.itemTotal) - element.htAmount
                        } else if (element.taxGroup == 'A') {
                          element.htAmount = element.itemTotal
                          inv.nonTaxableTotal += parseFloat(element.itemTotal)
                        } else if (element.taxGroup == 'E') {
                          element.htAmount = element.itemTotal
                          inv.tpsAmount += parseFloat(element.itemTotal)
                        } else if (element.taxGroup == 'C') {
                          element.htAmount = element.itemTotal
                          inv.taxableTotal += element.htAmount
                        }
                        // console.log(element.htAmount, inv.taxableTotal)
                        inv.totalHT += parseFloat(element.htAmount)
                      })

                      if (invoiceRequestDto.aib) {
                        inv.aibAmount = Math.round(
                          ((inv.nonTaxableTotal + inv.taxableTotal) *
                            (invoiceRequestDto.aib == 'A' ? 1 : 5)) /
                            100
                        )
                        inv.total -= inv.aibAmount
                      }
                      inv.vat = 18

                      let mcf = {
                        ...invoiceRequestDto,
                        ...res,
                        ...inv,
                      }
                      data.mcf = JSON.stringify(mcf)
                      data.invoice_type = 'S'
                      data.id = this.estimate.id

                      this.convertToInvoice(data)
                        .then((res) => {
                          this.isLoading = false
                          if (res.data) {
                            console.log(res.data.invoice)
                            window.toastr['success'](
                              this.$t('invoices.created_message')
                            )
                            this.$router.push(
                              `/admin/invoices/${res.data.invoice.id}/view`
                            )
                          }
                        })
                        .catch((err) => {
                          this.isLoading = false
                          if (err.response.data.errors.invoice_number) {
                            window.toastr['error'](
                              err.response.data.errors.invoice_number
                            )
                            return true
                          } else {
                            window.toastr['error'](err)
                            return true
                          }
                        })
                    })
                })
                .catch((err) => {
                  console.log(err)
                  this.isLoading = false
                  window.toastr['error']('Erreur')
                })
            }
          })
          .catch((err) => {
            this.isLoading = false
            window.toastr['error']('Impossible de joindre le serveur E-MECEF')
          })
      } else {
        this.isLoading = false
        window.toastr['error'](
          'Veuillez configurer votre compte EMECEF dans les paramètres !'
        )
      }
    },

    submitSave(data) {
      this.isLoading = true
      data = JSON.parse(data)
      let taxableGroups = ['B', 'D']
      let aibValue = data.aib ? data.aib : ''
      let tva = 18
      let items = data.items

      items.forEach((element) => {
        element.description = element.name
        element.price = element.price / 100
        element.vat = {
          type: element.group,
          value: taxableGroups.includes(element.group) ? tva : 0,
        }
      })

      var userId = Ls.get('user')
      var userName = Ls.get('userName')
      let today = new Date()
      console.log(data)
      console.log(this.estimate)
      let inv = {
        type: 'FV',
        orignal: '000',
        date: moment(today).toString(),
        reference: this.estimate.estimate_number,
        discount: this.estimate.discount,
        company: {
          name: this.companyInfos.name,
          address: this.companyInfos.address_street_1,
          contact: this.companyInfos.phone,
          ifu: this.companyInfos.ifu ? this.companyInfos.ifu : '1234567891234',
        },
        operator: {
          id: userId,
          name: userName,
        },
        customer: {
          name: this.estimate.user.contact_name,
          ifu: this.estimate.user.ifu
            ? this.estimate.user.ifu
            : '1111222233334',
          amount: data.total,
          change: 0,
        },
        tax: 0,
        aib: aibValue,
        products: items,
      }

      var myHeaders = new Headers()
      myHeaders.append('authorization', 'Bearer bWVjZWY6dXNlcg==')
      myHeaders.append('content-type', 'application/json')
      myHeaders.append('content', JSON.stringify(inv))

      var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
      }

      fetch('http://127.0.0.1:3480', myInit)
        .then((res) => {
          return res.json()
        })
        .then((res) => {
          // console.log(res)
          if (!res.success) {
            this.isLoading = false
            if (res.result == 'No device connected')
              res.result = 'Aucun appareil connecté'
            else if (res.result == 'Device not compatible')
              res.result = 'Appareil MECEF non compatible'
            window.toastr['error'](res.result)
          } else {
            data.mcf = JSON.stringify(res.result)
            data.invoice_type = 'N'
            data.id = this.estimate.id
            this.convertToInvoice(data)
              .then((res) => {
                this.isLoading = false
                if (res.data) {
                  console.log(res)
                  window.toastr['success'](this.$t('invoices.created_message'))
                  this.$router.push(
                    `/admin/invoices/${res.data.invoice.id}/view`
                  )
                }
              })
              .catch((err) => {
                this.isLoading = false
                if (err.response.data.errors.invoice_number) {
                  window.toastr['error'](
                    err.response.data.errors.invoice_number
                  )
                  return true
                } else {
                  window.toastr['error'](err)
                  return true
                }
              })
          }
        })
        .catch((err) => {
          this.isLoading = false
          window.toastr['error']('Impossible de joindre le serveur MECEF')
        })
    },

    async removeEstimate(id) {
      window
        .swal({
          title: 'Deleted',
          text: 'you will not be able to recover this estimate!',
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            let request = await this.deleteEstimate(id)
            if (request.data.success) {
              window.toastr['success'](this.$tc('estimates.deleted_message', 1))
              this.$router.push('/admin/estimates')
            } else if (request.data.error) {
              window.toastr['error'](request.data.message)
            }
          }
        })
    },
  },
}
</script>
