var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invoice-index-page invoices main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "d-flex flex-row" }, [
          _c("div", [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$tc("journal.list", 2)))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title"
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("journal.list", 2)) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-actions row" }, [
          _c(
            "div",
            { staticClass: "col-xs-2 mr-4" },
            [
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalJournal || _vm.filtersApplied,
                      expression: "totalJournal || filtersApplied"
                    }
                  ],
                  attrs: {
                    outline: true,
                    icon: _vm.filterIcon,
                    size: "large",
                    color: "theme",
                    "right-icon": ""
                  },
                  on: { click: _vm.toggleFilter }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-2" },
            [
              _c(
                "download-excel",
                {
                  staticClass: "btn btn-default btn-lg",
                  attrs: {
                    size: "large",
                    disabled: "",
                    icon: "file",
                    color: "theme",
                    data: _vm.fetchDataArray,
                    fields: _vm.json_fields,
                    worksheet: "Journal de caisse",
                    name: "journal_caisse.xls"
                  }
                },
                [_vm._v("\n          EXPORTER\n        ")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters"
              }
            ],
            staticClass: "filter-section"
          },
          [
            _c("div", { staticClass: "filter-container" }, [
              _c("div", { staticClass: "filter-date" }, [
                _c(
                  "div",
                  { staticClass: "from pr-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar"
                      },
                      model: {
                        value: _vm.filters.from_date,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "from_date", $$v)
                        },
                        expression: "filters.from_date"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dashed" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "to pl-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar"
                      },
                      model: {
                        value: _vm.filters.to_date,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "to_date", $$v)
                        },
                        expression: "filters.to_date"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen"
            }
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" }
        },
        [
          _c("satellite-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("journal.no_items")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" }
              },
              [_vm._v(_vm._s(_vm.$t("journal.list")))]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen"
            }
          ],
          staticClass: "table-container"
        },
        [
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table"
              }
            },
            [
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(row.index) }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("journal.libelle"), show: "libelle" }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("journal.type_operation"),
                  show: "type_operation"
                }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("journal.entree"), show: "entree" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              row.entree
                                ? _vm.$utils.formatMoney(
                                    row.entree,
                                    _vm.defaultCurrency
                                  )
                                : "------"
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("journal.sortie"), show: "sortie" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              row.sortie
                                ? _vm.$utils.formatMoney(
                                    row.sortie,
                                    _vm.defaultCurrency
                                  )
                                : "------"
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("journal.solde"), show: "solde" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              row.solde
                                ? _vm.$utils.formatMoney(
                                    row.solde,
                                    _vm.defaultCurrency
                                  )
                                : 0
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("journal.added_on"),
                  "sort-as": "created_at",
                  show: "created_at"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }