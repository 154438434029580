var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tax-select" }, [
    _c("div", { staticClass: "main-section" }, [
      _c(
        "div",
        { staticClass: "search-bar" },
        [
          _c("base-input", {
            staticClass: "search-input",
            attrs: {
              placeholder: _vm.$t("general.search"),
              focus: "",
              icon: "search",
              type: "text"
            },
            model: {
              value: _vm.textSearch,
              callback: function($$v) {
                _vm.textSearch = $$v
              },
              expression: "textSearch"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.filteredTaxType.length > 0
        ? _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.filteredTaxType, function(taxType, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "list-item",
                  class: {
                    "item-disabled":
                      _vm.taxes.find(function(val) {
                        return val.tax_type_id === taxType.id
                      }) || taxType.name === "TVA"
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectTaxType(index)
                    }
                  }
                },
                [
                  _c("label", [_vm._v(_vm._s(taxType.name))]),
                  _vm._v(" "),
                  _c("label", [_vm._v(_vm._s(taxType.percent) + " %")])
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "no-data-label" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("general.no_tax_found")))])
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }