var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item-selector" },
    [
      _vm.item.item_id
        ? _c("div", { staticClass: "selected-item" }, [
            _vm._v("\n    " + _vm._s(_vm.item.name) + "\n\n    "),
            _c(
              "span",
              { staticClass: "deselect-icon", on: { click: _vm.deselectItem } },
              [_c("font-awesome-icon", { attrs: { icon: "times-circle" } })],
              1
            )
          ])
        : _c("base-select", {
            ref: "baseSelect",
            staticClass: "multi-select-item",
            attrs: {
              options: _vm.allItems,
              loading: _vm.loading,
              "show-labels": false,
              "preserve-search": true,
              "initial-search": _vm.item.name,
              invalid: _vm.invalid,
              placeholder: _vm.$t("invoices.item.select_an_item"),
              label: "name"
            },
            on: { value: _vm.onTextChange, select: _vm.onSelect },
            model: {
              value: _vm.itemSelect,
              callback: function($$v) {
                _vm.itemSelect = $$v
              },
              expression: "itemSelect"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }