<template>
  <div class="main-content item-create">
    <div class="page-header">
      <h3 class="page-title">
        {{ $t('supply.add') }}
      </h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/admin/dashboard">{{
            $t('general.home')
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/admin/supply">{{
            $tc('supply.supply', 2)
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <a href="#"> {{ $t('supply.add') }}</a>
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <form action="" @submit.prevent="submitItem">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-sm-12">
                  <label class="control-label">{{ $t('supply.product') }}</label
                  ><span class="text-danger"> *</span>
                  <base-select
                    v-model="formData.item"
                    :options="productItems"
                    :searchable="true"
                    :show-labels="false"
                    :placeholder="$t('supply.select_a_product')"
                    label="name"
                  >
                  </base-select>
                  <div v-if="$v.formData.item.$error">
                    <span v-if="!$v.formData.item.required" class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                  </div>
                </div>
                <!-- <div class="form-group col-sm-6">
                  <label class="control-label">{{ $t('supply.unit') }}</label
                  ><span class="text-danger"> *</span>
                  <base-input
                    v-model.trim="formData.unit"
                    disabled
                    type="text"
                    name="unit"
                  />
                </div> -->
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label>{{ $t('supply.purchase_price') }}</label
                  ><span class="text-danger"> *</span>
                  <div class="base-input">
                    <money
                      :class="{ invalid: $v.formData.price.$error }"
                      v-model="price"
                      v-bind="defaultCurrencyForInput"
                      class="input-field"
                    />
                  </div>
                  <div v-if="$v.formData.price.$error">
                    <span v-if="!$v.formData.price.required" class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                    <span
                      v-if="!$v.formData.price.maxLength"
                      class="text-danger"
                      >{{ $t('validation.price_maxlength') }}</span
                    >
                    <span
                      v-if="!$v.formData.price.minValue"
                      class="text-danger"
                      >{{ $t('validation.price_minvalue') }}</span
                    >
                  </div>
                </div>

                <div class="form-group col-sm-6">
                  <label>{{ $t('supply.quantity') }}</label
                  ><span class="text-danger"> *</span>
                  <base-input
                    v-model.trim="formData.quantity"
                    :invalid="$v.formData.quantity.$error"
                    focus
                    type="number"
                    name="quantity"
                    @input="$v.formData.quantity.$touch()"
                  />
                  <div v-if="$v.formData.quantity.$error">
                    <span
                      v-if="!$v.formData.quantity.required"
                      class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                    <!-- <span
                      v-if="!$v.formData.quantity.minValue"
                      class="text-danger"
                      >{{ $t('validation.price_minvalue') }}</span
                    > -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-sm-6">
                  <base-button
                    :loading="isLoading"
                    :disabled="isLoading"
                    icon="save"
                    color="theme"
                    type="submit"
                    class="collapse-button"
                  >
                    {{ $t('items.save_item') }}
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapActions, mapGetters } from 'vuex'
const {
  required,
  numeric,
  minValue,
  maxLength,
} = require('vuelidate/lib/validators')

export default {
  mixins: {
    validationMixin,
  },
  data() {
    return {
      isLoading: false,
      title: 'Add Item',
      units: [],
      taxes: [],
      formData: {},
      money: {
        decimal: '.',
        thousands: ',',
        suffix: ' XOF',
        precision: 0,
        masked: false,
      },
    }
  },
  computed: {
    ...mapGetters('currency', ['defaultCurrencyForInput']),
    ...mapGetters('item', [
      'productItems',
      'selectedItems',
      'totalItems',
      'selectAllField',
      'itemUnits',
    ]),
    price: {
      get: function () {
        return this.formData.price / 100
      },
      set: function (newValue) {
        this.formData.price = newValue * 100
      },
    },
  },
  created() {
    this.fetchProductItems()
  },
  validations: {
    formData: {
      item: {
        required,
      },
      quantity: {
        required,
        // numeric,
        // minValue: minValue(2),
      },
      price: {
        required,
        numeric,
        maxLength: maxLength(20),
        minValue: minValue(0.1),
      },
    },
  },
  methods: {
    ...mapActions('item', [
      'fetchProductItems',
      'selectAllItems',
      'selectItem',
      'deleteItem',
      'deleteMultipleItems',
      'setSelectAllState',
    ]),

    ...mapActions('supply', ['addSupply', 'fetchSupply']),

    async submitItem() {
      this.$v.formData.$touch()
      if (this.$v.$invalid) {
        return false
      }
      this.isLoading = true
      // console.log(this.formData)
      if (this.formData.item) {
        this.formData.unit = this.formData.item.unit_name
        this.formData.item_id = this.formData.item.id
      }

      let response
      let data = {
        ...this.formData,
      }
      response = await this.addSupply(data)

      if (response.data) {
        this.isLoading = false
        window.toastr['success'](this.$tc('supply.added_message'))
        this.$router.push('/admin/supply')
        return true
      }
      window.toastr['error'](response.data.error)
    },
  },
}
</script>
