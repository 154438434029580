<template>
  <div v-if="invoice" class="main-content invoice-view-page">
    <div class="page-header">
      <h3 class="page-title">{{ invoice.invoice_number }}</h3>
      <div class="page-actions row">
        <div class="col-xs-2 mr-3">

        </div>

        <v-dropdown
          :close-on-select="true"
          align="left"
          class="filter-container"
        >
          <a slot="activator" href="#">
            <base-button color="theme">
              <font-awesome-icon icon="ellipsis-h" />
            </base-button>
          </a>
          <v-dropdown-item>
            <div class="dropdown-item" @click="copyPdfUrl">
              <font-awesome-icon
                :icon="['fas', 'link']"
                class="dropdown-item-icon"
              />
              {{ $t('general.copy_pdf_url') }}
            </div>
            <!-- <div class="dropdown-item" @click="removeInvoice($route.params.id)">
              <font-awesome-icon
                :icon="['fas', 'trash']"
                class="dropdown-item-icon"
              />
              {{ $t('general.delete') }}
            </div> -->
          </v-dropdown-item>
        </v-dropdown>
      </div>
    </div>
    <div class="invoice-sidebar">
      <div class="side-header">
        <base-input
          v-model="searchData.searchText"
          :placeholder="$t('general.search')"
          input-class="inv-search"
          icon="search"
          type="text"
          align-icon="right"
          @input="onSearch"
        />
        <div class="btn-group ml-3" role="group" aria-label="First group">
          <v-dropdown
            :close-on-select="false"
            align="left"
            class="filter-container"
          >
            <a slot="activator" href="#">
              <base-button
                class="inv-button inv-filter-fields-btn"
                color="default"
                size="medium"
              >
                <font-awesome-icon icon="filter" />
              </base-button>
            </a>
            <div class="filter-title">
              {{ $t('general.sort_by') }}
            </div>
            <div class="filter-items">
              <input
                id="filter_invoice_date"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="invoice_date"
                @change="onSearch"
              />
              <label class="inv-label" for="filter_invoice_date">{{
                $t('invoices.invoice_date')
              }}</label>
            </div>
            <div class="filter-items">
              <input
                id="filter_due_date"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="due_date"
                @change="onSearch"
              />
              <label class="inv-label" for="filter_due_date">{{
                $t('invoices.due_date')
              }}</label>
            </div>
            <div class="filter-items">
              <input
                id="filter_invoice_number"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="invoice_number"
                @change="onSearch"
              />
              <label class="inv-label" for="filter_invoice_number">{{
                $t('invoices.invoice_number')
              }}</label>
            </div>
          </v-dropdown>
          <base-button
            v-tooltip.top-center="{ content: getOrderName }"
            class="inv-button inv-filter-sorting-btn"
            color="default"
            size="medium"
            @click="sortData"
          >
            <font-awesome-icon v-if="getOrderBy" icon="sort-amount-up" />
            <font-awesome-icon v-else icon="sort-amount-down" />
          </base-button>
        </div>
      </div>
      <base-loader v-if="isSearching" />
      <div v-else class="side-content">
        <router-link
          v-for="(invoice, index) in invoices"
          :to="`/admin/invoices/${invoice.id}/view`"
          :key="index"
          class="side-invoice"
        >
          <div class="left">
            <div class="inv-name">{{ invoice.user.name }}</div>
            <div class="inv-number">{{ invoice.invoice_number }}</div>
          </div>
          <div class="right">
            <div
              class="inv-amount"
              v-html="
                $utils.formatMoney(invoice.due_amount, invoice.user.currency)
              "
            />
            <div class="inv-date">{{ invoice.formattedInvoiceDate }}</div>
          </div>
        </router-link>
        <p v-if="!invoices.length" class="no-result">
          {{ $t('invoices.no_matching_invoices') }}
        </p>
      </div>
    </div>
    <div class="invoice-view-page-container">
      <iframe :src="`${shareableLink}`" class="frame-style" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const _ = require('lodash')
export default {
  data() {
    return {
      id: null,
      count: null,
      invoices: [],
      invoice: null,
      currency: null,
      searchData: {
        orderBy: null,
        orderByField: null,
        searchText: null,
      },
      isRequestOnGoing: false,
      isSearching: false,
      isSendingEmail: false,
      isMarkingAsSent: false,
    }
  },
  computed: {
    getOrderBy() {
      if (
        this.searchData.orderBy === 'asc' ||
        this.searchData.orderBy == null
      ) {
        return true
      }
      return false
    },
    getOrderName() {
      if (this.getOrderBy) {
        return this.$t('general.ascending')
      }
      return this.$t('general.descending')
    },
    shareableLink() {
      return `/invoices/pdf/${this.invoice.unique_hash}`
    },
  },
  watch: {
    $route(to, from) {
      this.loadInvoice()
    },
  },
  created() {
    this.loadInvoices()
    this.loadInvoice()
    this.onSearch = _.debounce(this.onSearch, 500)
  },
  methods: {
    ...mapActions('invoice', [
      'fetchInvoices',
      'getRecord',
      'searchInvoice',
      'markAsSent',
      'sendEmail',
      'deleteInvoice',
      'selectInvoice',
      'fetchViewInvoice',
    ]),
    async loadInvoices() {
      let response = await this.fetchInvoices()
      if (response.data) {
        this.invoices = response.data.invoices.data
      }
    },
    async loadInvoice() {
      let response = await this.fetchViewInvoice(this.$route.params.id)

      if (response.data) {
        this.invoice = response.data.invoice
      }
    },
    async onSearch() {
      let data = ''
      if (
        this.searchData.searchText !== '' &&
        this.searchData.searchText !== null &&
        this.searchData.searchText !== undefined
      ) {
        data += `search=${this.searchData.searchText}&`
      }

      if (
        this.searchData.orderBy !== null &&
        this.searchData.orderBy !== undefined
      ) {
        data += `orderBy=${this.searchData.orderBy}&`
      }

      if (
        this.searchData.orderByField !== null &&
        this.searchData.orderByField !== undefined
      ) {
        data += `orderByField=${this.searchData.orderByField}`
      }
      this.isSearching = true
      let response = await this.searchInvoice(data)
      this.isSearching = false
      if (response.data) {
        this.invoices = response.data.invoices.data
      }
    },
    sortData() {
      if (this.searchData.orderBy === 'asc') {
        this.searchData.orderBy = 'desc'
        this.onSearch()
        return true
      }
      this.searchData.orderBy = 'asc'
      this.onSearch()
      return true
    },
    async onMarkAsSent() {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('invoices.invoice_mark_as_sent'),
          icon: '/assets/icon/check-circle-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            this.isMarkingAsSent = true
            let response = await this.markAsSent({ id: this.invoice.id })
            this.isMarkingAsSent = false
            if (response.data) {
              window.toastr['success'](
                this.$tc('invoices.marked_as_sent_message')
              )
            }
          }
        })
    },
    async onSendInvoice() {
      window
        .swal({
          title: this.$tc('general.are_you_sure'),
          text: this.$tc('invoices.confirm_send_invoice'),
          icon: '/assets/icon/paper-plane-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            this.isSendingEmail = true
            let response = await this.sendEmail({ id: this.invoice.id })
            this.isSendingEmail = false
            if (response.data.success) {
              window.toastr['success'](
                this.$tc('invoices.send_invoice_successfully')
              )
              return true
            }
            if (response.data.error === 'user_email_does_not_exist') {
              window.toastr['error'](
                this.$tc('invoices.user_email_does_not_exist')
              )
              return false
            }
            window.toastr['error'](this.$tc('invoices.something_went_wrong'))
          }
        })
    },
    copyPdfUrl() {
      let pdfUrl = `${window.location.origin}/invoices/pdf/${this.invoice.unique_hash}`

      let response = this.$utils.copyTextToClipboard(pdfUrl)

      window.toastr['success'](this.$tc('Copied PDF url to clipboard!'))
    },
    async removeInvoice(id) {
      this.selectInvoice([parseInt(id)])
      this.id = id
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('invoices.confirm_delete', 2),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            let currentInvoice = this.invoices.find((_inv) => _inv.id === id)
            if (currentInvoice && currentInvoice.invoice_type === 'N') {
              currentInvoice = JSON.parse(currentInvoice.mcf)
              let items = []
              currentInvoice.items.forEach((element) => {
                items.push({
                  description: element.name,
                  quantity: element.quantity,
                  price: element.unitPrice,
                  vat: {
                    type: element.vatType,
                    value: element.vat,
                  },
                })
              })

              let inv = {
                type: 'FA',
                original: currentInvoice.nim + '-' + currentInvoice.tc,
                date: currentInvoice.date,
                reference: currentInvoice.ref,
                discount: 0,
                company: {
                  name: currentInvoice.companyName,
                  address: currentInvoice.companyAdress,
                  contact: currentInvoice.companyContact,
                  ifu: currentInvoice.companyIfu
                    ? currentInvoice.companyIfu
                    : '1234567891234',
                },
                operator: {
                  id: currentInvoice.operatorId,
                  name: currentInvoice.operatorName,
                },
                customer: {
                  name: currentInvoice.customerName,
                  ifu: currentInvoice.customerIfu
                    ? currentInvoice.customerIfu
                    : '1111222233334',
                  amount: currentInvoice.total,
                  change: 0,
                },
                tax: 0,
                aib: 0,
                products: items,
              }
              console.log(inv)

              var myHeaders = new Headers()
              myHeaders.append('authorization', 'Bearer bWVjZWY6dXNlcg==')
              myHeaders.append('content-type', 'application/json')
              myHeaders.append('content', JSON.stringify(inv))

              var myInit = {
                method: 'POST',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
              }

              fetch('http://127.0.0.1:3480', myInit)
                .then((res) => {
                  return res.json()
                })
                .then((res) => {
                  console.log(res)
                  if (!res.success) {
                    if (res.result == 'No device connected')
                      res.result = 'Aucun appareil connecté'
                    else if (res.result == 'Device not compatible')
                      res.result = 'Appareil MECEF non compatible'
                    window.toastr['error'](res.result)
                  } else {
                    this.processDelete(id)
                  }
                })
                .catch((err) => {
                  this.isLoading = false
                  console.log(error)
                  window.toastr['error'](
                    'Impossible de joindre le serveur MECEF ' + err
                  )
                })
            } else {

              this.processDelete(id)
            }
          }
        })
    },

    async processDelete(id) {
      let request = await this.deleteInvoice(this.id)
      if (request.data.success) {
        window.toastr['success'](this.$tc('invoices.deleted_message', 1))
        this.$router.push('/admin/invoices')
      } else if (request.data.error) {
        window.toastr['error'](request.data.message)
      }
    },
  },
}
</script>
