var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-main-container" }, [
    _c("div", { staticClass: "card setting-card" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$tc("settings.preferences.preference", 2)))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "page-sub-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("settings.preferences.general_settings")) +
              "\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.updatePreferencesData($event)
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6 mb-4 form-group" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.preferences.currency")))
                ]),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                _vm._v(" "),
                _c("base-select", {
                  class: { error: _vm.$v.formData.currency.$error },
                  attrs: {
                    options: _vm.currencies,
                    "custom-label": _vm.currencyNameWithCode,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    placeholder: _vm.$tc("settings.currencies.select_currency"),
                    label: "name",
                    "track-by": "id"
                  },
                  model: {
                    value: _vm.formData.currency,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "currency", $$v)
                    },
                    expression: "formData.currency"
                  }
                }),
                _vm._v(" "),
                _vm.$v.formData.currency.$error
                  ? _c("div", [
                      !_vm.$v.formData.currency.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required")))
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4 form-group" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.preferences.language")))
                ]),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                _vm._v(" "),
                _c("base-select", {
                  class: { error: _vm.$v.formData.language.$error },
                  attrs: {
                    options: _vm.languages,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    placeholder: _vm.$tc(
                      "settings.preferences.select_language"
                    ),
                    label: "name",
                    "track-by": "code"
                  },
                  model: {
                    value: _vm.formData.language,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "language", $$v)
                    },
                    expression: "formData.language"
                  }
                }),
                _vm._v(" "),
                _vm.$v.formData.language.$error
                  ? _c("div", [
                      !_vm.$v.formData.language.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required")))
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4 form-group" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.preferences.time_zone")))
                ]),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                _vm._v(" "),
                _c("base-select", {
                  class: { error: _vm.$v.formData.timeZone.$error },
                  attrs: {
                    options: _vm.timeZones,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    placeholder: _vm.$tc(
                      "settings.preferences.select_time_zone"
                    ),
                    label: "key",
                    "track-by": "key"
                  },
                  model: {
                    value: _vm.formData.timeZone,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "timeZone", $$v)
                    },
                    expression: "formData.timeZone"
                  }
                }),
                _vm._v(" "),
                _vm.$v.formData.timeZone.$error
                  ? _c("div", [
                      !_vm.$v.formData.timeZone.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required")))
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4 form-group" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.preferences.date_format")))
                ]),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                _vm._v(" "),
                _c("base-select", {
                  class: { error: _vm.$v.formData.dateFormat.$error },
                  attrs: {
                    options: _vm.dateFormats,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    placeholder: _vm.$tc(
                      "settings.preferences.select_date_formate"
                    ),
                    label: "display_date"
                  },
                  model: {
                    value: _vm.formData.dateFormat,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "dateFormat", $$v)
                    },
                    expression: "formData.dateFormat"
                  }
                }),
                _vm._v(" "),
                _vm.$v.formData.dateFormat.$error
                  ? _c("div", [
                      !_vm.$v.formData.dateFormat.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required")))
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4 form-group" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.preferences.fiscal_year")))
                ]),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                _vm._v(" "),
                _c("base-select", {
                  class: { error: _vm.$v.formData.fiscalYear.$error },
                  attrs: {
                    options: _vm.fiscalYears,
                    "show-labels": false,
                    "allow-empty": false,
                    searchable: true,
                    placeholder: _vm.$tc(
                      "settings.preferences.select_financial_year"
                    ),
                    label: "key",
                    "track-by": "value"
                  },
                  model: {
                    value: _vm.formData.fiscalYear,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "fiscalYear", $$v)
                    },
                    expression: "formData.fiscalYear"
                  }
                }),
                _vm._v(" "),
                _vm.$v.formData.fiscalYear.$error
                  ? _c("div", [
                      !_vm.$v.formData.fiscalYear.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tc("settings.company_info.errors.required")
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c(
              "div",
              { staticClass: "col-md-12 input-group" },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      icon: "save",
                      color: "theme",
                      type: "submit"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$tc("settings.company_info.save")) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }