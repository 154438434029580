<template>
  <div class="user-create main-content">
    <form action="" @submit.prevent="submituserData">
      <div class="page-header">
        <h3 class="page-title">
          {{ isEdit ? $t('users.edit_user') : $t('users.new_user') }}
        </h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/admin/dashboard">{{
              $t('general.home')
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/admin/users">{{
              $tc('users.users', 2)
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            {{ isEdit ? $t('users.edit_user') : $t('users.new_user') }}
          </li>
        </ol>
        <div class="page-actions header-button-container">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            :tabindex="23"
            icon="save"
            color="theme"
            type="submit"
          >
            {{ isEdit ? $t('users.update_user') : $t('users.save_user') }}
          </base-button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="user-card card">
            <div class="card-body">
              <div class="form-group">
                <label class="form-label">{{ $t('users.display_name') }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.name.$error"
                  v-model="formData.name"
                  focus
                  type="text"
                  name="name"
                  tab-index="1"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{
                      $tc(
                        'validation.name_min_length',
                        $v.formData.name.$params.minLength.min,
                        { count: $v.formData.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('users.email') }}</label>
                <base-input
                  :invalid="$v.formData.email.$error"
                  v-model.trim="formData.email"
                  type="text"
                  name="email"
                  tab-index="3"
                  @input="$v.formData.email.$touch()"
                />
                <div v-if="$v.formData.email.$error">
                  <span v-if="!$v.formData.email.email" class="text-danger">
                    {{ $tc('validation.email_incorrect') }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('users.phone') }}</label>
                <base-input
                  v-model.trim="formData.phone"
                  type="text"
                  name="phone"
                  tab-index="4"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('users.role') }}</label>
                <base-select
                  v-model="formData.role"
                  :options="roles"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  :tabindex="6"
                  :placeholder="$t('users.role')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MultiSelect from 'vue-multiselect'
import { validationMixin } from 'vuelidate'
const {
  required,
  minLength,
  email,
  url,
  maxLength,
} = require('vuelidate/lib/validators')

export default {
  components: { MultiSelect },
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      formData: {
        name: null,
        email: null,
        phone: null,
        role: null,
      },
      roles: ['admin', 'caissière', 'controlleur'],
    }
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        email,
      },
      phone: {
        required,
      },
      role: {
        required,
      },
    },
  },
  computed: {
    isEdit() {
      if (this.$route.name === 'users.edit') {
        return true
      }
      return false
    },
  },
  watch: {},
  mounted() {
    if (this.isEdit) {
      this.loaduser()
    }
  },
  methods: {
    ...mapActions('users', ['addUser', 'fetchUser', 'updateUser']),
    async loaduser() {
      let {
        data: { user },
      } = await this.fetchuser(this.$route.params.id)

      this.formData.id = user.id
      this.formData.name = user.name
      this.formData.contact_name = user.contact_name
      this.formData.email = user.email
      this.formData.phone = user.phone
      this.formData.role = user.role
    },

    async submituserData() {
      this.$v.formData.$touch()

      if (this.$v.$invalid) {
        return true
      }

      if (this.isEdit) {
        this.isLoading = true
        try {
          let response = await this.updateUser(this.formData)
          if (response.data.success) {
            window.toastr['success'](this.$t('users.updated_message'))
            this.$router.push('/admin/users')
            this.isLoading = false
            return true
          } else {
            this.isLoading = false
            if (response.data.error) {
              window.toastr['error'](this.$t('validation.email_already_taken'))
            }
          }
        } catch (err) {
          if (err.response.data.errors.email) {
            this.isLoading = false
            window.toastr['error'](this.$t('validation.email_already_taken'))
          }
        }
      } else {
        this.isLoading = true

        try {
          let response = await this.addUser(this.formData)
          if (response.data.success) {
            window.toastr['success'](this.$t('users.created_message'))
            this.$router.push('/admin/users')
            this.isLoading = false
            return true
          }
        } catch (err) {
          this.isLoading = false
          if (err.response.data.errors.email) {
            window.toastr['error'](this.$t('validation.email_already_taken'))
          }
        }
      }
    },
  },
}
</script>
