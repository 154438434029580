<template>
  <div class="invoice-create-page main-content">
    <form v-if="!initLoading" action="" @submit.prevent="submitInvoiceData">
      <div class="page-header">
        <h3 v-if="$route.name === 'invoices.edit'" class="page-title">
          {{ $t('invoices.edit_invoice') }}
        </h3>
        <h3 v-else class="page-title">{{ $t('invoices.new_invoice') }}</h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/admin/dashboard">{{
              $t('general.home')
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/admin/invoices">{{
              $tc('invoices.invoice', 2)
            }}</router-link>
          </li>
          <li v-if="$route.name === 'invoices.edit'" class="breadcrumb-item">
            {{ $t('invoices.edit_invoice') }}
          </li>
          <li v-else class="breadcrumb-item">
            {{ $t('invoices.new_invoice') }}
          </li>
        </ol>
        <div class="page-actions row">
          <a
            v-if="$route.name === 'invoices.edit'"
            :href="`/invoices/pdf/${newInvoice.unique_hash}`"
            target="_blank"
            class="
              mr-3
              invoice-action-btn
              base-button
              btn btn-outline-primary
              default-size
            "
            outline
            color="theme"
          >
            {{ $t('general.view_pdf') }}
          </a>
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            icon="save"
            color="theme"
            class="invoice-action-btn"
            type="submit"
          >
            {{ $t('invoices.save_invoice') }}
          </base-button>
        </div>
      </div>
      <div class="row invoice-input-group">
        <div class="col-md-5 invoice-customer-container">
          <div v-if="selectedCustomer" class="show-customer">
            <div class="row px-2 mt-1">
              <div v-if="selectedCustomer.billing_address" class="col col-6">
                <div class="row address-menu">
                  <label class="col-sm-4 px-2 title">{{
                    $t('general.bill_to')
                  }}</label>
                  <div class="col-sm p-0 px-2 content">
                    <label v-if="selectedCustomer.billing_address.name">
                      {{ selectedCustomer.billing_address.name }}
                    </label>
                    <label
                      v-if="selectedCustomer.billing_address.address_street_1"
                    >
                      {{ selectedCustomer.billing_address.address_street_1 }}
                    </label>
                    <label
                      v-if="selectedCustomer.billing_address.address_street_2"
                    >
                      {{ selectedCustomer.billing_address.address_street_2 }}
                    </label>
                    <label
                      v-if="
                        selectedCustomer.billing_address.city &&
                        selectedCustomer.billing_address.state
                      "
                    >
                      {{ selectedCustomer.billing_address.city }},
                      {{ selectedCustomer.billing_address.state }}
                      {{ selectedCustomer.billing_address.zip }}
                    </label>
                    <label v-if="selectedCustomer.billing_address.country">
                      {{ selectedCustomer.billing_address.country.name }}
                    </label>
                    <label v-if="selectedCustomer.billing_address.phone">
                      {{ selectedCustomer.billing_address.phone }}
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="selectedCustomer.shipping_address" class="col col-6">
                <div class="row address-menu">
                  <label class="col-sm-4 px-2 title">{{
                    $t('general.ship_to')
                  }}</label>
                  <div class="col-sm p-0 px-2 content">
                    <label v-if="selectedCustomer.shipping_address.name">
                      {{ selectedCustomer.shipping_address.name }}
                    </label>
                    <label
                      v-if="selectedCustomer.shipping_address.address_street_1"
                    >
                      {{ selectedCustomer.shipping_address.address_street_1 }}
                    </label>
                    <label
                      v-if="selectedCustomer.shipping_address.address_street_2"
                    >
                      {{ selectedCustomer.shipping_address.address_street_2 }}
                    </label>
                    <label
                      v-if="
                        selectedCustomer.shipping_address.city &&
                        selectedCustomer.shipping_address
                      "
                    >
                      {{ selectedCustomer.shipping_address.city }},
                      {{ selectedCustomer.shipping_address.state }}
                      {{ selectedCustomer.shipping_address.zip }}
                    </label>
                    <label
                      v-if="selectedCustomer.shipping_address.country"
                      class="country"
                    >
                      {{ selectedCustomer.shipping_address.country.name }}
                    </label>
                    <label
                      v-if="selectedCustomer.shipping_address.phone"
                      class="phone"
                    >
                      {{ selectedCustomer.shipping_address.phone }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="customer-content mb-1">
              <label class="email">{{ selectedCustomer.name }}</label>
              <label class="action" @click="editCustomer">{{
                $t('general.edit')
              }}</label>
              <label class="action" @click="removeCustomer">{{
                $t('general.deselect')
              }}</label>
            </div>
          </div>

          <base-popup
            v-else
            :class="[
              'add-customer',
              { 'customer-required': $v.selectedCustomer.$error },
            ]"
          >
            <div slot="activator" class="add-customer-action">
              <font-awesome-icon icon="user" class="customer-icon" />
              <div>
                <label
                  >{{ $t('customers.new_customer') }}
                  <span class="text-danger"> * </span></label
                >
                <p
                  v-if="
                    $v.selectedCustomer.$error && !$v.selectedCustomer.required
                  "
                  class="text-danger"
                >
                  {{ $t('validation.required') }}
                </p>
              </div>
            </div>
            <customer-select-popup type="invoice" />
          </base-popup>
        </div>
        <div class="col invoice-input">
          <div class="row mb-3">
            <div class="col collapse-input">
              <label
                >{{ $tc('invoices.invoice', 1) }} {{ $t('invoices.date')
                }}<span class="text-danger"> * </span></label
              >
              <base-date-picker
                v-model="newInvoice.invoice_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
                @change="$v.newInvoice.invoice_date.$touch()"
              />
              <span
                v-if="
                  $v.newInvoice.invoice_date.$error &&
                  !$v.newInvoice.invoice_date.required
                "
                class="text-danger"
              >
                {{ $t('validation.required') }}
              </span>
            </div>
            <div class="col collapse-input">
              <label
                >{{ $t('invoices.due_type')
                }}<span class="text-danger"> * </span></label
              >
              <base-select
                v-model="newInvoice.payment_option"
                :options="optionGroups"
                :searchable="false"
                :show-labels="false"
                icon="hashtag"
                :placeholder="$t('invoices.due_type')"
                @change="checkType()"
              >
              </base-select>
              <span
                v-if="
                  $v.newInvoice.payment_option.$error &&
                  !$v.newInvoice.payment_option.required
                "
                class="text-danger mt-1"
              >
                {{ $t('validation.required') }}</span
              >
            </div>
          </div>
          <div class="row mt-4">
            <div class="col collapse-input">
              <label
                >{{ $t('invoices.invoice_number')
                }}<span class="text-danger"> * </span></label
              >
              <base-prefix-input
                v-model="invoiceNumAttribute"
                :invalid="$v.invoiceNumAttribute.$error"
                :prefix="invoicePrefix"
                icon="hashtag"
                @input="$v.invoiceNumAttribute.$touch()"
              />
              <span
                v-show="
                  $v.invoiceNumAttribute.$error &&
                  !$v.invoiceNumAttribute.required
                "
                class="text-danger mt-1"
              >
                {{ $tc('validation.required') }}
              </span>
              <span
                v-show="!$v.invoiceNumAttribute.numeric"
                class="text-danger mt-1"
              >
                {{ $tc('validation.numbers_only') }}
              </span>
            </div>

            <!-- <div class="col collapse-input">
              <label
                >{{ $t('invoices.mecef_type')
                }}<span class="text-danger"> * </span></label
              >
              <base-select
                v-model="newInvoice.mecef_type"
                :options="mecefGroups"
                :searchable="false"
                :show-labels="false"
                icon="hashtag"
                :placeholder="$t('invoices.mecef_type')"
              >
              </base-select>
            </div> -->

            <div class="col collapse-input" v-if="showDue">
              <label>{{ $t('invoices.due_date') }}</label>
              <base-date-picker
                v-model="newInvoice.due_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
          </div>
        </div>
      </div>
      <table class="item-table">
        <colgroup>
          <col style="width: 40%" />
          <col style="width: 10%" />
          <col style="width: 15%" />
          <!-- <col v-if="discountPerItem === 'YES'" style="width: 15%" /> -->
          <col style="width: 15%" />
        </colgroup>
        <thead class="item-table-header">
          <tr>
            <th class="text-left">
              <span class="column-heading item-heading">
                {{ $tc('items.item', 2) }}
              </span>
            </th>
            <th class="text-right">
              <span class="column-heading">
                {{ $t('invoices.item.quantity') }}
              </span>
            </th>
            <th class="text-left">
              <span class="column-heading">
                {{ $t('invoices.item.price') }}
              </span>
            </th>
            <!-- <th v-if="discountPerItem === 'YES'" class="text-right">
              <span class="column-heading">
                {{ $t('invoices.item.discount') }}
              </span>
            </th> -->
            <th class="text-right">
              <span class="column-heading amount-heading">
                {{ $t('invoices.item.amount') }}
              </span>
            </th>
          </tr>
        </thead>
        <draggable
          v-model="newInvoice.items"
          class="item-body"
          tag="tbody"
          handle=".handle"
        >
          <invoice-item
            v-for="(item, index) in newInvoice.items"
            :key="item.id"
            :index="index"
            :item-data="item"
            :invoice-items="newInvoice.items"
            :currency="currency"
            :tax-per-item="taxPerItem"
            :discount-per-item="discountPerItem"
            @remove="removeItem"
            @update="updateItem"
            @itemValidate="checkItemsData"
          />
        </draggable>
      </table>
      <div class="add-item-action" @click="addItem">
        <font-awesome-icon icon="shopping-basket" class="mr-2" />
        {{ $t('invoices.add_item') }}
      </div>

      <div class="invoice-foot">
        <div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="control-label">Titre Signataire</label>
              <base-input
                v-model.trim="newInvoice.signataire_titre"
                focus
                type="text"
                name="signataire_titre"
              />
            </div>
            <div class="form-group col-sm-6">
              <label class="control-label">Nom Signataire</label>
              <base-input
                v-model.trim="newInvoice.signataire_nom"
                focus
                type="text"
                name="signataire_nom"
              />
            </div>
          </div>
          <!-- <label>{{ $t('invoices.notes') }}</label>
          <base-text-area
            v-model="newInvoice.notes"
            rows="3"
            cols="50"
            @input="$v.newInvoice.notes.$touch()"
          />
          <div v-if="$v.newInvoice.notes.$error">
            <span v-if="!$v.newInvoice.notes.maxLength" class="text-danger">{{
              $t('validation.notes_maxlength')
            }}</span>
          </div> -->
          <!-- <label class="mt-3 mb-1 d-block"
            >{{ $t('invoices.invoice_template') }}
            <span class="text-danger"> * </span></label
          >
          <base-button
            type="button"
            class="btn-template"
            icon="pencil-alt"
            right-icon
            @click="openTemplateModal"
          >
            <span class="mr-4">
              {{ $t('invoices.template') }} {{ getTemplateId }}
            </span>
          </base-button> -->
        </div>

        <div class="invoice-total">
          <div class="section">
            <label class="invoice-label">{{ $t('invoices.sub_total') }}</label>
            <label class="invoice-amount">
              <div v-html="$utils.formatMoney(subtotal, currency)" />
            </label>
          </div>
          <div v-for="tax in allTaxes" :key="tax.tax_type_id" class="section">
            <label class="invoice-label"
              >{{ tax.name }} - {{ tax.percent }}%
            </label>
            <label class="invoice-amount">
              <div v-html="$utils.formatMoney(tax.amount, currency)" />
            </label>
          </div>
          <!--
          <div
            v-if="discountPerItem === 'NO' || discountPerItem === null"
            class="section mt-2"
          >
            <label class="invoice-label">{{ $t('invoices.discount') }}</label>
            <div class="btn-group discount-drop-down" role="group">
              <base-input
                v-model="discount"
                :invalid="$v.newInvoice.discount_val.$error"
                input-class="item-discount"
                @input="$v.newInvoice.discount_val.$touch()"
              />
            </div>
          </div>
          -->

          <div v-if="taxPerItem === 'NO' || taxPerItem === null">
            <tax
              v-for="(tax, index) in newInvoice.taxes"
              :index="index"
              :total="subtotalWithDiscount"
              :key="tax.id"
              :tax="tax"
              :taxes="newInvoice.taxes"
              :currency="currency"
              :total-tax="totalSimpleTax"
              @remove="removeInvoiceTax"
              @update="updateTax"
            />
          </div>

          <base-popup
            v-if="taxPerItem === 'NO' || taxPerItem === null"
            ref="taxModal"
            class="tax-selector"
          >
            <div slot="activator" class="float-right">
              + {{ $t('invoices.add_tax') }}
            </div>
            <tax-select-popup :taxes="newInvoice.taxes" @select="onSelectTax" />
          </base-popup>

          <div class="section border-top mt-3">
            <label class="invoice-label"
              >{{ $t('invoices.total') }} {{ $t('invoices.amount') }}:</label
            >
            <label class="invoice-amount total">
              <div v-html="$utils.formatMoney(total, currency)" />
            </label>
          </div>
        </div>
      </div>
    </form>
    <base-loader v-else />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import MultiSelect from 'vue-multiselect'
import InvoiceItem from './Item'
import InvoiceStub from '../../stub/invoice'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import Guid from 'guid'
import TaxStub from '../../stub/tax'
import Tax from './InvoiceTax'
import Ls from '@/services/ls'
const {
  required,
  between,
  maxLength,
  numeric,
} = require('vuelidate/lib/validators')

export default {
  components: {
    InvoiceItem,
    MultiSelect,
    Tax,
    draggable,
  },
  mixins: [validationMixin],
  data() {
    return {
      newInvoice: {
        invoice_date: null,
        due_date: null,
        mecef_type: null,
        payment_option: 'Au comptant',
        invoice_number: null,
        mecefData: {},
        user_id: null,
        invoice_template_id: 1,
        sub_total: null,
        total: null,
        tax: null,
        notes: null,
        discount_type: 'fixed',
        discount_val: 0,
        discount: 0,
        reference_number: null,
        items: [
          {
            ...InvoiceStub,
            id: Guid.raw(),
            taxes: [{ ...TaxStub, id: Guid.raw() }],
          },
        ],
        taxes: [],
      },
      companyInfos: {},
      customers: [],
      optionGroups: ['Au comptant', 'A terme'],
      mecefGroups: ['E-MECEF', 'MECEF'],
      itemList: [],
      invoiceTemplates: [],
      selectedCurrency: '',
      taxPerItem: null,
      discountPerItem: null,
      initLoading: false,
      isLoading: false,
      maxDiscount: 0,
      invoicePrefix: null,
      invoiceNumAttribute: null,
    }
  },
  validations() {
    return {
      newInvoice: {
        invoice_date: {
          required,
        },
        // due_date: {
        //   required,
        // },
        payment_option: {
          required,
        },
        discount_val: {
          between: between(0, this.subtotal),
        },
        notes: {
          maxLength: maxLength(255),
        },
        reference_number: {
          maxLength: maxLength(255),
        },
      },
      selectedCustomer: {
        required,
      },
      invoiceNumAttribute: {
        required,
        numeric,
      },
    }
  },
  computed: {
    ...mapGetters('general', ['itemDiscount']),
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('invoice', ['getTemplateId', 'selectedCustomer']),
    ...mapGetters('userProfile', ['user']),
    ...mapGetters('company', {
      selectedCompany: 'getSelectedCompany',
      companies: 'getCompanies',
    }),

    showDue() {
      if (this.newInvoice.payment_option === 'Au comptant') {
        return false
      } else {
        return true
      }
    },

    currency() {
      return this.selectedCurrency
    },
    subtotalWithDiscount() {
      return this.subtotal - this.newInvoice.discount_val
    },
    total() {
      return this.subtotalWithDiscount - this.totalTax
    },
    subtotal() {
      return this.newInvoice.items.reduce(function (a, b) {
        return a + b['total']
      }, 0)
    },
    discount: {
      get: function () {
        return this.newInvoice.discount
      },
      set: function (newValue) {
        if (this.newInvoice.discount_type === 'percentage') {
          this.newInvoice.discount_val = (this.subtotal * newValue) / 100
        } else {
          this.newInvoice.discount_val = newValue * 100
        }

        this.newInvoice.discount = newValue
      },
    },
    totalSimpleTax() {
      return window._.sumBy(this.newInvoice.taxes, function (tax) {
        if (!tax.compound_tax) {
          return tax.amount
        }

        return 0
      })
    },

    totalCompoundTax() {
      return window._.sumBy(this.newInvoice.taxes, function (tax) {
        if (tax.compound_tax) {
          return tax.amount
        }

        return 0
      })
    },
    totalTax() {
      if (this.taxPerItem === 'NO' || this.taxPerItem === null) {
        return this.totalSimpleTax + this.totalCompoundTax
      }

      return window._.sumBy(this.newInvoice.items, function (tax) {
        return tax.tax
      })
    },
    allTaxes() {
      let taxes = []

      this.newInvoice.items.forEach((item) => {
        item.taxes.forEach((tax) => {
          let found = taxes.find((_tax) => {
            return _tax.tax_type_id === tax.tax_type_id
          })

          if (found) {
            found.amount += tax.amount
          } else if (tax.tax_type_id) {
            taxes.push({
              tax_type_id: tax.tax_type_id,
              amount: tax.amount,
              percent: tax.percent,
              name: tax.name,
            })
          }
        })
      })

      return taxes
    },
  },
  watch: {
    selectedCustomer(newVal) {
      if (newVal && newVal.currency) {
        this.selectedCurrency = newVal.currency
      } else {
        this.selectedCurrency = this.defaultCurrency
      }
    },
    subtotal(newValue) {
      if (this.newInvoice.discount_type === 'percentage') {
        this.newInvoice.discount_val =
          (this.newInvoice.discount * newValue) / 100
      }
    },
  },
  created() {
    this.loadInfos()
    this.fetchInitialItems()
    this.resetSelectedCustomer()
    window.hub.$on('newTax', this.onSelectTax)

    this.setInitialData()
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('invoice', [
      'addInvoice',
      'fetchCreateInvoice',
      'fetchInvoice',
      'resetSelectedCustomer',
      'selectCustomer',
      'updateInvoice',
    ]),
    ...mapActions('item', ['fetchAllItems']),
    ...mapActions('emecef', ['loadMecef']),

    ...mapActions('companyInfo', ['loadData']),

    async setInitialData() {
      let response = await this.loadData()
      this.companyInfos.name = response.data.user.company.name
      this.companyInfos.ifu = response.data.user.company.ifu
      this.companyInfos.address_street_1 =
        response.data.user.addresses[0].address_street_1
      this.companyInfos.address_street_2 =
        response.data.user.addresses[0].address_street_2
      this.companyInfos.zip = response.data.user.addresses[0].zip
      this.companyInfos.phone = response.data.user.addresses[0].phone
      this.companyInfos.state = response.data.user.addresses[0].state
      this.companyInfos.city = response.data.user.addresses[0].city

      let mecefResponse = await this.loadMecef()
      this.mecefData = mecefResponse.data
    },

    selectFixed() {
      if (this.newInvoice.discount_type === 'fixed') {
        return
      }

      this.newInvoice.discount_val = this.newInvoice.discount * 100
      this.newInvoice.discount_type = 'fixed'
    },
    selectPercentage() {
      if (this.newInvoice.discount_type === 'percentage') {
        return
      }

      this.newInvoice.discount_val =
        (this.subtotal * this.newInvoice.discount) / 100

      this.newInvoice.discount_type = 'percentage'
    },
    updateTax(data) {
      if (data.item.name === 'AIB1' || data.item.name === 'AIB5') {
        let taxableGroups = ['B', 'D']
        let nonTaxableTotal = 0
        this.newInvoice.items.forEach((element) => {
          let htAmount = 0
          if (taxableGroups.includes(element.group)) {
            htAmount = Math.round(
              parseInt(element.total / 100) * parseFloat(100 / (100 + 18))
            )
            nonTaxableTotal += htAmount
          } else {
            nonTaxableTotal += parseFloat(element.total / 100)
            htAmount = element.total / 100
          }
        })
        data.item.amount =
          Math.round((nonTaxableTotal * data.item.percent) / 100) * 100
      }

      // console.log(data.item)
      Object.assign(this.newInvoice.taxes[data.index], { ...data.item })
    },
    async fetchInitialItems() {
      await this.fetchAllItems({
        filter: {},
        orderByField: '',
        orderBy: '',
      })
    },
    async loadInfos() {
      if (this.$route.name === 'invoices.edit') {
        this.initLoading = true
        let response = await this.fetchInvoice(this.$route.params.id)

        if (response.data) {
          this.selectCustomer(response.data.invoice.user_id)
          this.newInvoice = response.data.invoice
          this.newInvoice.invoice_date = moment(
            response.data.invoice.invoice_date,
            'YYYY-MM-DD'
          ).toString()
          this.newInvoice.due_date = moment(
            response.data.invoice.due_date,
            'YYYY-MM-DD'
          ).toString()
          this.discountPerItem = response.data.discount_per_item
          this.taxPerItem = response.data.tax_per_item
          this.selectedCurrency = this.defaultCurrency
          this.invoiceTemplates = response.data.invoiceTemplates
          this.invoicePrefix = response.data.invoice_prefix
          this.invoiceNumAttribute = response.data.nextInvoiceNumber
        }
        this.initLoading = false
        return
      }

      this.initLoading = true
      let response = await this.fetchCreateInvoice()
      if (response.data) {
        this.discountPerItem = response.data.discount_per_item
        this.taxPerItem = response.data.tax_per_item
        this.selectedCurrency = this.defaultCurrency
        this.invoiceTemplates = response.data.invoiceTemplates
        let today = new Date()
        this.newInvoice.invoice_date = moment(today).toString()
        this.newInvoice.due_date = moment(today).add(7, 'days').toString()
        this.itemList = response.data.items
        this.invoicePrefix = response.data.invoice_prefix
        this.invoiceNumAttribute = response.data.nextInvoiceNumberAttribute
      }
      this.initLoading = false
    },
    removeCustomer() {
      this.resetSelectedCustomer()
    },
    editCustomer() {
      this.openModal({
        title: this.$t('customers.edit_customer'),
        componentName: 'CustomerModal',
        id: this.selectedCustomer.id,
        data: this.selectedCustomer,
      })
    },
    openTemplateModal() {
      this.openModal({
        title: this.$t('general.choose_template'),
        componentName: 'InvoiceTemplate',
        data: this.invoiceTemplates,
      })
    },
    addItem() {
      this.newInvoice.items.push({
        ...InvoiceStub,
        id: Guid.raw(),
        taxes: [{ ...TaxStub, id: Guid.raw() }],
      })
    },
    removeItem(index) {
      this.newInvoice.items.splice(index, 1)
    },
    updateItem(data) {
      Object.assign(this.newInvoice.items[data.index], { ...data.item })
    },
    submitInvoiceData() {
      if (!this.checkValid()) {
        window.toastr['error']('Veuillez bien remplir tous les champs requis.')
        return false
      }
      this.newInvoice.invoice_number =
        this.invoicePrefix + '-' + this.invoiceNumAttribute

      let data = {
        ...this.newInvoice,
        invoice_date: moment(this.newInvoice.invoice_date).format('DD/MM/YYYY'),
        due_date: moment(this.newInvoice.due_date).format('DD/MM/YYYY'),
        sub_total: this.subtotal,
        total: this.total,
        tax: this.totalTax,
        user_id: null,
        invoice_template_id: this.getTemplateId,
      }

      if (this.selectedCustomer != null) {
        data.user_id = this.selectedCustomer.id
      }

      // console.log(this.mecefData.invoice_type);

      if (this.mecefData.invoice_type === 'EMECEF') {
        this.submitEmecef(data)
      } else {
        this.submitSave(data)
      }
    },
    // mecef_type
    submitEmecef(data) {
      this.isLoading = true
      if (this.mecefData.jeton) {
        var myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + this.mecefData.jeton)
        myHeaders.append('content-type', 'application/json')

        var myInit = {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'default',
        }

        fetch(this.mecefData.url + '/info/status', myInit)
          .then((res) => {
            return res.json()
          })
          .then((res) => {
            if (res.status == 401) {
              window.toastr['error']('Accès non autorisé.')
              this.isLoading = false
            } else {
              let items = JSON.parse(JSON.stringify(data.items))
              let invoiceItems = []

              var userId = Ls.get('user')
              var userName = Ls.get('userName')

              items.forEach((element) => {
                invoiceItems.push({
                  name: element.name,
                  price: element.price / 100,
                  quantity: element.quantity / 1,
                  taxGroup: element.group,
                })
              })
              let aib1 = data.taxes.find((_tax) => {
                return _tax.name === 'AIB1'
              })
              let aib5 = data.taxes.find((_tax) => {
                return _tax.name === 'AIB5'
              })
              let invoiceRequestDto = {
                ifu: res.ifu,
                type: 'FV',
                aib: aib1 ? 'A' : aib5 ? 'B' : null,
                items: invoiceItems,
                operator: {
                  id: userId,
                  name: userName,
                },
                client: {
                  name: this.selectedCustomer.name,
                  ifu: this.selectedCustomer.ifu
                    ? this.selectedCustomer.ifu
                    : '1111222233334',
                },
              }

              // POST REQUEST
              var myInitPost = {
                method: 'POST',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(invoiceRequestDto),
              }

              fetch(this.mecefData.url + '/invoice', myInitPost)
                .then((res) => {
                  return res.json()
                })
                .then((res) => {
                  var myInitPut = {
                    method: 'PUT',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default',
                  }
                  fetch(
                    this.mecefData.url + '/invoice/' + res.uid + '/confirm',
                    myInitPut
                  )
                    .then((res) => {
                      return res.json()
                    })
                    .then((res) => {
                      ///**** Calculer les informations manquantes à EMECEF */
                      let inv = {}

                      inv.taxableTotal = 0
                      inv.nonTaxableTotal = 0
                      inv.totalHT = 0
                      inv.aibAmount = 0
                      inv.vatAmount = 0
                      inv.tpsAmount = 0
                      inv.total = 0

                      invoiceRequestDto.items.forEach((element) => {
                        element.itemTotal = element.price * element.quantity
                        inv.total += parseFloat(element.itemTotal)

                        if (
                          element.taxGroup == 'B' ||
                          element.taxGroup == 'D'
                        ) {
                          element.htAmount = Math.round(
                            element.itemTotal * parseFloat(100 / 118)
                          )
                          inv.taxableTotal += element.htAmount
                          inv.vatAmount +=
                            parseFloat(element.itemTotal) - element.htAmount
                        } else if (element.taxGroup == 'A') {
                          element.htAmount = element.itemTotal
                          inv.nonTaxableTotal += parseFloat(element.itemTotal)
                        } else if (element.taxGroup == 'E') {
                          element.htAmount = element.itemTotal
                          inv.tpsAmount += parseFloat(element.itemTotal)
                        } else if (element.taxGroup == 'C') {
                          element.htAmount = element.itemTotal
                          inv.taxableTotal += element.htAmount
                        }
                        // console.log(element.htAmount, inv.taxableTotal)
                        inv.totalHT += parseFloat(element.htAmount)
                      })

                      if (invoiceRequestDto.aib) {
                        inv.aibAmount = Math.round(
                          ((inv.nonTaxableTotal + inv.taxableTotal) *
                            (invoiceRequestDto.aib == 'A' ? 1 : 5)) /
                            100
                        )
                        inv.total -= inv.aibAmount
                      }
                      inv.vat = 18

                      let mcf = {
                        ...invoiceRequestDto,
                        ...res,
                        ...inv,
                      }
                      // console.log(mcf)
                      data.mcf = JSON.stringify(mcf)
                      data.invoice_type = 'S'

                      this.addInvoice(data)
                        .then((res) => {
                          this.isLoading = false
                          if (res.data) {
                            // console.log(res.data.invoice)
                            window.toastr['success'](
                              this.$t('invoices.created_message')
                            )
                            this.$router.push(
                              `/admin/invoices/${res.data.invoice.id}/view`
                            )
                          }
                        })
                        .catch((err) => {
                          this.isLoading = false
                          if (err.response.data.errors.invoice_number) {
                            window.toastr['error'](
                              err.response.data.errors.invoice_number
                            )
                            return true
                          } else {
                            window.toastr['error'](err)
                            return true
                          }
                        })
                    })
                })
                .catch((err) => {
                  console.log(err)
                  this.isLoading = false
                  window.toastr['error']('Erreur')
                })
            }
          })
          .catch((err) => {
            this.isLoading = false
            window.toastr['error']('Impossible de joindre le serveur E-MECEF')
          })
      } else {
        this.isLoading = false
        window.toastr['error'](
          'Veuillez configurer votre compte EMECEF dans les paramètres !'
        )
      }
    },

    submitSave(data) {
      this.isLoading = true
      let aibValue = ''
      let aib1 = data.taxes.find((_tax) => {
        return _tax.name === 'AIB1'
      })
      let aib5 = data.taxes.find((_tax) => {
        return _tax.name === 'AIB5'
      })
      let tva = this.allTaxes.find((_tax) => {
        return _tax.name === 'TVA'
      })
      let taxableGroups = ['B', 'D']
      // CONFIG AIB
      if (aib1) {
        aibValue = 1
      } else if (aib5) {
        aibValue = 5
      }
      // CONFIG TVA
      if (!tva) tva = 18
      let items = JSON.parse(JSON.stringify(data.items))

      items.forEach((element) => {
        element.description = element.name
        element.price = element.price / 100
        element.vat = {
          type: element.group,
          value: taxableGroups.includes(element.group) ? tva : 0,
        }
        delete element.id
        delete element.invoice_id
        delete element.group
        delete element.item_id
        delete element.title
        delete element.title
        delete element.total
        delete element.totalCompoundTax
        delete element.unit_name
        delete element.valid
        delete element.name
        delete element.discount
        delete element.discount_type
        delete element.discount_val
        delete element.tax
        delete element.taxes
        delete element.totalSimpleTax
        delete element.totalTax
      })

      data.items.forEach((element) => {
        element.name = element.name + ' [' + element.group + ']'
      })

      var userId = Ls.get('user')
      var userName = Ls.get('userName')
      let inv = {
        type: 'FV',
        orignal: '000',
        date: data.invoice_date,
        reference: data.invoice_number,
        discount: data.discount_val,
        company: {
          name: this.companyInfos.name,
          address: this.companyInfos.address_street_1,
          contact: this.companyInfos.phone,
          ifu: this.companyInfos.ifu ? this.companyInfos.ifu : '1234567891234',
        },
        operator: {
          id: userId,
          name: userName,
        },
        customer: {
          name: this.selectedCustomer.name,
          ifu: this.selectedCustomer.ifu
            ? this.selectedCustomer.ifu
            : '1111222233334',
          amount: data.sub_total / 100,
          change: 0,
        },
        tax: 0,
        aib: aibValue,
        products: items,
      }

      var myHeaders = new Headers()
      myHeaders.append('authorization', 'Bearer bWVjZWY6dXNlcg==')
      myHeaders.append('content-type', 'application/json')
      myHeaders.append('content', JSON.stringify(inv))

      var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
      }

      fetch('http://127.0.0.1:3480', myInit)
        .then((res) => {
          return res.json()
        })
        .then((res) => {
          // console.log(res)
          if (!res.success) {
            this.isLoading = false
            if (res.result == 'No device connected')
              res.result = 'Aucun appareil connecté'
            else if (res.result == 'Device not compatible')
              res.result = 'Appareil MECEF non compatible'
            window.toastr['error'](res.result)
          } else {
            data.mcf = JSON.stringify(res.result)
            data.invoice_type = 'N'
            this.addInvoice(data)
              .then((res) => {
                this.isLoading = false
                if (res.data) {
                  // console.log(res.data)
                  window.toastr['success'](this.$t('invoices.created_message'))
                  this.$router.push(
                    `/admin/invoices/${res.data.invoice.id}/view`
                  )
                }
              })
              .catch((err) => {
                this.isLoading = false
                if (err.response.data.errors.invoice_number) {
                  window.toastr['error'](
                    err.response.data.errors.invoice_number
                  )
                  return true
                } else {
                  window.toastr['error'](err)
                  return true
                }
              })
          }
        })
        .catch((err) => {
          this.isLoading = false
          window.toastr['error']('Impossible de joindre le serveur MECEF')
        })
    },

    checkItemsData(index, isValid) {
      this.newInvoice.items[index].valid = isValid
    },
    onSelectTax(selectedTax) {
      let amount = 0
      // Calculer les taxes quand il s'agit d'AIB
      if (selectedTax.name === 'AIB1' || selectedTax.name === 'AIB5') {
        let taxableGroups = ['B', 'D']
        let nonTaxableTotal = 0
        this.newInvoice.items.forEach((element) => {
          if (taxableGroups.includes(element.group)) {
            let htAmount = Math.round(
              parseInt(element.total / 100) * parseFloat(100 / (100 + 18))
            )
            nonTaxableTotal += htAmount
          } else if (element.group == 'E') {
            nonTaxableTotal += 0
          } else {
            nonTaxableTotal += parseFloat(element.total / 100)
          }
        })

        amount = Math.round((nonTaxableTotal * selectedTax.percent) / 100) * 100
      } else if (selectedTax.compound_tax && this.subtotalWithDiscount) {
        amount =
          ((this.subtotalWithDiscount + this.totalSimpleTax) *
            selectedTax.percent) /
          100
      } else if (this.subtotalWithDiscount && selectedTax.percent) {
        amount = (this.subtotalWithDiscount * selectedTax.percent) / 100
      }

      this.newInvoice.taxes.push({
        ...TaxStub,
        id: Guid.raw(),
        name: selectedTax.name,
        percent: selectedTax.percent,
        compound_tax: selectedTax.compound_tax,
        tax_type_id: selectedTax.id,
        amount,
      })

      this.$refs.taxModal.close()
    },
    removeInvoiceTax(index) {
      this.newInvoice.taxes.splice(index, 1)
    },
    checkValid() {
      this.$v.newInvoice.$touch()
      this.$v.selectedCustomer.$touch()

      window.hub.$emit('checkItems')
      let isValid = true
      this.newInvoice.items.forEach((item) => {
        // console.log(item)
        if (!item.valid) {
          let qte = item.quantity ? parseInt(item.quantity) : 0
          if (
            (item.item_id != '' &&
              item.type == 'article' &&
              qte > 0 &&
              item.stock >= qte) ||
            item.type == 'service'
          ) {
            item.valid = true
          }
        }
      })
      this.newInvoice.items.forEach((item) => {
        if (!item.item_id) {
          item.valid = false
        }

        if (!item.valid) {
          isValid = false
        }
      })
      if (
        !this.$v.selectedCustomer.$invalid &&
        this.$v.newInvoice.$invalid === false &&
        isValid === true
      ) {
        return true
      }
      return false
    },
  },
}
</script>
