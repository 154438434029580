<template>
  <div class="invoice-index-page invoices main-content">
    <div class="page-header">
      <div class="d-flex flex-row">
        <div>
          <h3 class="page-title">{{ $tc('journal.list', 2) }}</h3>
        </div>
      </div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t('general.home') }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc('journal.list', 2) }}
          </router-link>
        </li>
      </ol>

      <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="totalJournal || filtersApplied"
            :outline="true"
            :icon="filterIcon"
            size="large"
            color="theme"
            right-icon
            @click="toggleFilter"
          >
            {{ $t('general.filter') }}
          </base-button>
        </div>

        <div class="col-xs-2">
          <!-- <base-button size="large" icon="file" color="theme">
            EXPORTER
          </base-button> -->
          <download-excel size="large"
          disabled
            class="btn btn-default btn-lg"
             icon="file" color="theme"
            :data="fetchDataArray"
            :fields="json_fields"
            worksheet="Journal de caisse"
            name="journal_caisse.xls"
          >
            EXPORTER
          </download-excel>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="filter-container">
          <div class="filter-date">
            <div class="from pr-3">
              <label>{{ $t('general.from') }}</label>
              <base-date-picker
                v-model="filters.from_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
            <div class="dashed" />
            <div class="to pl-3">
              <label>{{ $t('general.to') }}</label>
              <base-date-picker
                v-model="filters.to_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
          </div>
        </div>
        <label class="clear-filter" @click="clearFilter">{{
          $t('general.clear_all')
        }}</label>
      </div>
    </transition>

    <div
      v-cloak
      v-show="showEmptyScreen"
      class="col-xs-1 no-data-info"
      align="center"
    >
      <satellite-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t('journal.no_items') }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t('journal.list')
        }}</label>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <!-- <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t('general.showing') }} <b>{{ journal.length }}</b>
          {{ $t('general.of') }} <b>{{ totalJournal }}</b>
        </p>
        <div></div>
      </div> -->

      <table-component
        ref="table"
        :data="fetchData"
        :show-filter="false"
        table-class="table"
      >
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <div v-html="row.index" />
          </template>
        </table-column>
        <table-column :label="$t('journal.libelle')" show="libelle" />
        <table-column
          :label="$t('journal.type_operation')"
          show="type_operation"
        />
        <table-column :label="$t('journal.entree')" show="entree">
          <template slot-scope="row">
            <div
              v-html="
                row.entree
                  ? $utils.formatMoney(row.entree, defaultCurrency)
                  : '------'
              "
            />
          </template>
        </table-column>
        <table-column :label="$t('journal.sortie')" show="sortie">
          <template slot-scope="row">
            <div
              v-html="
                row.sortie
                  ? $utils.formatMoney(row.sortie, defaultCurrency)
                  : '------'
              "
            />
          </template>
        </table-column>
        <table-column :label="$t('journal.solde')" show="solde">
          <template slot-scope="row">
            <div
              v-html="
                row.solde ? $utils.formatMoney(row.solde, defaultCurrency) : 0
              "
            />
          </template>
        </table-column>
        <table-column
          :label="$t('journal.added_on')"
          sort-as="created_at"
          show="created_at"
        />
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DotIcon from '../../components/icon/DotIcon'
import SatelliteIcon from '../../components/icon/SatelliteIcon'
import moment from 'moment'
import BaseButton from '../../../js/components/base/BaseButton'

export default {
  components: {
    DotIcon,
    SatelliteIcon,
    BaseButton,
  },
  data() {
    return {
      id: null,
      showFilters: false,
      sortedBy: 'created_at',
      isRequestOngoing: true,
      fetchDataArray: [],
      filtersApplied: false,
      filters: {
        from_date: '',
        to_date: '',
      },
      json_fields: {
        'Libellé': 'libelle',
        'Opération': 'type_operation',
        // 'Entrée': 'entree',
        // 'Sortie': 'sortie',
        // 'Solde': 'solde',
        'Entrée': {
          field: 'entree',
          callback: (value) => {
            return value ? this.$utils.formatMoney(value, this.defaultCurrency) : '-----'
          },
        },
        'Sortie': {
          field: 'sortie',
          callback: (value) => {
            return value ? this.$utils.formatMoney(value, this.defaultCurrency) : '-----'
          },
        },
        'Solde': {
          field: 'solde',
          callback: (value) => {
            return this.$utils.formatMoney(value, this.defaultCurrency)
          },
        },
        'Date': 'created_at',
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },
  computed: {
    ...mapGetters('journal', [
      'journal',
      'selectedJournal',
      'totalJournal',
      'selectAllField',
      'itemUnits',
    ]),
    ...mapGetters('currency', ['defaultCurrency']),
    showEmptyScreen() {
      return (
        !this.totalJournal && !this.isRequestOngoing && !this.filtersApplied
      )
    },
    listAll() {
      return []
    },

    filterIcon() {
      return this.showFilters ? 'times' : 'filter'
    },
    selectField: {
      get: function () {
        return this.selectedJournal
      },
      set: function (val) {
        this.selectItem(val)
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  destroyed() {
    if (this.selectAllField) {
      this.selectAllJournal()
    }
  },
  methods: {
    ...mapActions('journal', [
      'fetchJournal',
      'selectAllJournal',
      'selectItem',
      'setSelectAllState',
    ]),

    setFilters() {
      this.filtersApplied = true
      this.refreshTable()
    },
    clearFilter() {
      this.filters = {
        from_date: '',
        to_date: '',
      }

      this.$nextTick(() => {
        this.filtersApplied = false
      })
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter()
        this.refreshTable()
      }

      this.showFilters = !this.showFilters
    },

    refreshTable() {
      this.$refs.table.refresh()
    },
    async fetchData({ page, filter, sort }) {
      let data = {
        from_date:
          this.filters.from_date === ''
            ? this.filters.from_date
            : moment(this.filters.from_date).format('DD/MM/YYYY'),
        to_date:
          this.filters.to_date === ''
            ? this.filters.to_date
            : moment(this.filters.to_date).format('DD/MM/YYYY'),
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchJournal(data)
      this.fetchDataArray = response.data.journal.data;
      this.isRequestOngoing = false

      return {
        data: response.data.journal.data,
        pagination: {
          totalPages: response.data.journal.last_page,
          currentPage: page,
        },
      }
    },

    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter()
        this.refreshTable()
      }

      this.showFilters = !this.showFilters
    },
  },
}
</script>
