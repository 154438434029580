<template>
  <div class="setting-main-container">
    <form action="" @submit.prevent="updateEmecef">
      <div class="card setting-card">
        <div class="page-header">
          <h3 class="page-title">TYPE DE FACTURE</h3>
          <p class="page-sub-title">
            Vous pouvez mettre à jour le type de facture édité par votre
            système. Pour celà, nous vous prions de contacter les
            administrateurs du système pour une assistance.
          </p>
        </div>
        <div class="row">
          <div class="col-md-12 mb-4 form-group">
            <label class="input-label">Type de facture par défaut</label>
            <span class="text-danger"> *</span>
            <base-select
              v-model="formData.invoice_type"
              :options="itemTypes"
              :invalid="$v.formData.invoice_type.$error"
              @change="$v.formData.invoice_type.$touch()"
              :searchable="true"
              placeholder="Choisir un type"
              :show-labels="false"
            >
            </base-select>
            <div v-if="$v.formData.invoice_type.$error">
              <span
                v-if="!$v.formData.invoice_type.required"
                class="text-danger"
                >{{ $t('validation.required') }}
              </span>
            </div>
          </div>
          <div class="col-md-12 mb-4 form-group">
            <label class="input-label">Mot de passe admin</label>
            <span class="text-danger"> *</span>
            <base-input
              placeholder="********"
              :invalid="$v.MotDePasse.$error"
              type="password"
              v-model="MotDePasse"
            />
            <div v-if="$v.MotDePasse.$error">
              <span v-if="!$v.MotDePasse.required" class="text-danger"
                >{{ $t('validation.required') }}
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 input-group">
            <base-button
              :loading="isLoading"
              :disabled="isLoading"
              icon="save"
              color="theme"
              type="submit"
            >
              {{ $tc('settings.account_settings.save') }}
            </base-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { mapActions } from 'vuex'
const { required } = require('vuelidate/lib/validators')
export default {
  mixins: [validationMixin],
  data() {
    return {
      formData: {
        invoice_type: null,
      },
      MotDePasse: null,
      itemTypes: ['MECEF', 'EMECEF'],
      isLoading: false,
    }
  },
  validations: {
    formData: {
      invoice_type: {
        required,
      },
    },
    MotDePasse: {
      required,
    },
  },
  computed: {},
  mounted() {
    this.setInitialData()
  },
  methods: {
    ...mapActions('emecef', ['loadMecef', 'editMecef']),

    checkType() {
      this.formData.url =
        this.formData.type == 'live'
          ? 'https://sygmef.impots.bj/emcf/api'
          : 'https://developper.impots.bj/sygmef-emcf/api'
    },

    async setInitialData() {
      let response = await this.loadMecef()
      this.formData.jeton = response.data.jeton
      this.formData.nim = response.data.nim
      this.formData.url = response.data.url
      this.formData.type = response.data.type
      this.formData.mdp = response.data.mdp
      this.formData.invoice_type = response.data.invoice_type
    },

    async updateEmecef() {
      if (!this.MotDePasse) {
        window.toastr['error']('Mot de passe obligatoire')
      } else {
        if (this.MotDePasse != this.formData.mdp) {
          window.toastr['error']('Mot de passe incorrect, accès interdit !')
        } else {
          swal({
            title: 'ATTENTION',
            text: 'Vous êtes sur le point de modifier le type de facture par défaut. Vouslez-vous continuer ?',
            buttons: true,
            dangerMode: true,
          }).then(async (willContinue) => {
            if (willContinue) {
              this.$v.formData.$touch()
              if (this.$v.$invalid) {
                return true
              }
              this.isLoading = true
              let data = {
                jeton: this.formData.jeton,
                nim: this.formData.nim,
                invoice_type: this.formData.invoice_type,
                url: this.formData.url,
                type: this.formData.type,
                mdp: this.formData.mdp,
              }
              let response = await this.editMecef(data)
              if (response.data.success) {
                this.isLoading = false
                window.toastr['success']("Paramètres mis à jour avec succès")
                return true
              }
              window.toastr['error'](response.data.error)
              return true
            }
          })
        }
      }
    },
  },
}
</script>
