var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invoice-index-page invoices main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "d-flex flex-row" }, [
          _c("div", [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$tc("supply.supply", 2)))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title"
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("supply.supply", 2)) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "div",
              { staticClass: "col-xs-2 mr-4" },
              [
                _c(
                  "base-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.totalSupply || _vm.filtersApplied,
                        expression: "totalSupply || filtersApplied"
                      }
                    ],
                    attrs: {
                      outline: true,
                      icon: _vm.filterIcon,
                      color: "theme",
                      size: "large",
                      "right-icon": ""
                    },
                    on: { click: _vm.toggleFilter }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.filter")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "col-xs-2 mr-4",
                attrs: { slot: "item-title", to: "supply/create" },
                slot: "item-title"
              },
              [
                _c(
                  "base-button",
                  { attrs: { color: "theme", icon: "plus", size: "large" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("supply.add")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xs-2" },
              [
                _c(
                  "download-excel",
                  {
                    staticClass: "btn btn-default btn-lg",
                    attrs: {
                      size: "large",
                      disabled: "",
                      icon: "file",
                      color: "theme",
                      data: _vm.fetchDataArray,
                      fields: _vm.json_fields,
                      worksheet: "Document",
                      name: "liste_appro.xls"
                    }
                  },
                  [_vm._v("\n          EXPORTER\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters"
              }
            ],
            staticClass: "filter-section"
          },
          [
            _c("div", { staticClass: "filter-container" }, [
              _c("div", { staticClass: "filter-date" }, [
                _c(
                  "div",
                  { staticClass: "from pr-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar"
                      },
                      model: {
                        value: _vm.filters.from_date,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "from_date", $$v)
                        },
                        expression: "filters.from_date"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dashed" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "to pl-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar"
                      },
                      model: {
                        value: _vm.filters.to_date,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "to_date", $$v)
                        },
                        expression: "filters.to_date"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen"
            }
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" }
        },
        [
          _c("satellite-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("supply.no_items")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" }
              },
              [_vm._v(_vm._s(_vm.$t("supply.list_of_items")))]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mt-3",
                  attrs: { outline: true, color: "theme", size: "large" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("supply/create")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("supply.add_new_item")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen"
            }
          ],
          staticClass: "table-container"
        },
        [
          _c(
            "div",
            { staticClass: "table-actions mt-5" },
            [
              _c("p", { staticClass: "table-stats" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.supply.length))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalSupply))])
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.selectedSupply.length
                    ? _c(
                        "v-dropdown",
                        { attrs: { "show-arrow": false } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "table-actions-button dropdown-toggle",
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: { click: _vm.removeMultipleItems }
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "trash"] }
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectAllFieldStatus,
                  expression: "selectAllFieldStatus"
                }
              ],
              staticClass: "custom-control-input",
              attrs: { id: "select-all", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.selectAllFieldStatus)
                  ? _vm._i(_vm.selectAllFieldStatus, null) > -1
                  : _vm.selectAllFieldStatus
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.selectAllFieldStatus,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.selectAllFieldStatus = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectAllFieldStatus = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectAllFieldStatus = $$c
                    }
                  },
                  _vm.selectAllSupply
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isRequestOngoing,
                    expression: "!isRequestOngoing"
                  }
                ],
                staticClass: "custom-control-label selectall",
                attrs: { for: "select-all" }
              },
              [
                _c("span", { staticClass: "select-all-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.select_all")) + " ")
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table"
              }
            },
            [
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(row.index) }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("supply.product"), show: "item_id" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(row.item.name) }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("supply.unit"), show: "unit" }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("supply.quantity"), show: "quantity" }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("supply.price"), show: "price" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("supply.price")) + " ")
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.price,
                                _vm.defaultCurrency
                              )
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("supply.added_on"),
                  "sort-as": "created_at",
                  show: "created_at"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }