var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invoice-index-page invoices main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("canceledInvoices.title")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title"
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("canceledInvoices.invoice", 2)) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-actions row" }, [
          _c(
            "div",
            { staticClass: "col-xs-2 mr-4" },
            [
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalInvoices || _vm.filtersApplied,
                      expression: "totalInvoices || filtersApplied"
                    }
                  ],
                  attrs: {
                    outline: true,
                    icon: _vm.filterIcon,
                    size: "large",
                    color: "theme",
                    "right-icon": ""
                  },
                  on: { click: _vm.toggleFilter }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-2" },
            [
              _c(
                "download-excel",
                {
                  staticClass: "btn btn-default btn-lg",
                  attrs: {
                    size: "large",
                    disabled: "",
                    icon: "file",
                    color: "theme",
                    data: _vm.fetchDataArray,
                    fields: _vm.json_fields,
                    worksheet: "Document",
                    name: "liste_factures_annulees.xls"
                  }
                },
                [_vm._v("\n          EXPORTER\n        ")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters"
              }
            ],
            staticClass: "filter-section"
          },
          [
            _c("div", { staticClass: "filter-container" }, [
              _c("div", { staticClass: "filter-date" }, [
                _c(
                  "div",
                  { staticClass: "from pr-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar"
                      },
                      model: {
                        value: _vm.filters.from_date,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "from_date", $$v)
                        },
                        expression: "filters.from_date"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dashed" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "to pl-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar"
                      },
                      model: {
                        value: _vm.filters.to_date,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "to_date", $$v)
                        },
                        expression: "filters.to_date"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen"
            }
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" }
        },
        [
          _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("canceledInvoices.no_invoices")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" }
              },
              [_vm._v(_vm._s(_vm.$t("canceledInvoices.list_of_invoices")))]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mt-3",
                  attrs: { outline: true, color: "theme", size: "large" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("invoices/create")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("canceledInvoices.new_invoice")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen"
            }
          ],
          staticClass: "table-container"
        },
        [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectAllFieldStatus,
                  expression: "selectAllFieldStatus"
                }
              ],
              staticClass: "custom-control-input",
              attrs: { id: "select-all", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.selectAllFieldStatus)
                  ? _vm._i(_vm.selectAllFieldStatus, null) > -1
                  : _vm.selectAllFieldStatus
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.selectAllFieldStatus,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.selectAllFieldStatus = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectAllFieldStatus = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectAllFieldStatus = $$c
                    }
                  },
                  _vm.selectAllInvoices
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isRequestOngoing,
                    expression: "!isRequestOngoing"
                  }
                ],
                staticClass: "custom-control-label selectall",
                attrs: { for: "select-all" }
              },
              [
                _c("span", { staticClass: "select-all-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.select_all")) + " ")
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table"
              }
            },
            [
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "custom-control custom-checkbox" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectField,
                                  expression: "selectField"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: { id: row.id, type: "checkbox" },
                              domProps: {
                                value: row.id,
                                checked: Array.isArray(_vm.selectField)
                                  ? _vm._i(_vm.selectField, row.id) > -1
                                  : _vm.selectField
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.selectField,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = row.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectField = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectField = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectField = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label",
                              attrs: { for: row.id }
                            })
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("canceledInvoices.date"),
                  "sort-as": "invoice_date",
                  show: "formattedInvoiceDate"
                }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("canceledInvoices.number"),
                  show: "invoice_number"
                }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("canceledInvoices.amount"),
                  "sort-as": "due_amount"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("canceledInvoices.amount_due")))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(row.due_amount)
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("items.date_of_creation"),
                  show: "formattedCreatedAt"
                }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown no-click"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("canceledInvoices.action")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-dropdown",
                          [
                            _c(
                              "a",
                              {
                                attrs: { slot: "activator", href: "#" },
                                slot: "activator"
                              },
                              [_c("dot-icon")],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-dropdown-item",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: {
                                        path:
                                          "canceled-invoices/" +
                                          row.id +
                                          "/view"
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: "eye" }
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("canceledInvoices.view")
                                        ) +
                                        "\n              "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }