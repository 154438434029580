import * as types from './mutation-types'

export default {
  [types.SET_MECEF] (state, data) {
    state.mecef = data
  },

  [types.UPDATE_MECEF] (state, data) {
    state.mecef = data
  },
}
