<template>
  <div class="setting-main-container">
    <form action="" @submit.prevent="updateEmecef">
      <div class="card setting-card">
        <div class="page-header">
          <h3 class="page-title">
            {{ $t('settings.emecef.emecef') }}
          </h3>
          <p class="page-sub-title">
            {{ $t('settings.emecef.section_description') }}
          </p>
        </div>
        <div class="row">
          <div class="col-md-12 mb-4 form-group">
            <label class="input-label">{{
              $tc('settings.emecef.jeton')
            }}</label>
            <base-input :disabled="formData.invoice_type=='MECEF'" v-model="formData.jeton" />
          </div>
          <div class="col-md-12 mb-4 form-group">
            <label class="input-label">{{ $tc('settings.emecef.nim') }}</label>
            <base-input :disabled="formData.invoice_type=='MECEF'" v-model="formData.nim" />
          </div>
          <!-- <div class="col-md-12 mb-4 form-group">
            <label class="input-label">{{ $tc('settings.emecef.url') }}</label>
            <base-input disabled v-model="formData.url" />
          </div> -->
          <div class="col-md-12 mb-4 form-group">
            <label class="input-label">{{ $tc('settings.emecef.type') }}</label>
            <span class="text-danger"> *</span>
            <base-select
              :disabled="formData.invoice_type=='MECEF'"
              v-model="formData.type"
              :options="itemTypes"
              :invalid="$v.formData.type.$error"
              @change="$v.formData.type.$touch()"
              :searchable="true"
              placeholder="Choisir un type"
              :show-labels="false"
            >
            </base-select>
             <div v-if="$v.formData.type.$error">
                    <span v-if="!$v.formData.type.required" class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                  </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 input-group">
            <base-button
              :loading="isLoading"
              :disabled="isLoading || formData.invoice_type == 'MECEF'"
              icon="save"
              color="theme"
              type="submit"
            >
              {{ $tc('settings.account_settings.save') }}
            </base-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { mapActions } from 'vuex'
const {
  required
} = require('vuelidate/lib/validators')
export default {
  mixins: [validationMixin],
  data() {
    return {
      formData: {
        jeton: null,
        nim: null,
        url: null,
        type: null,
        invoice_type: null,
      },
      itemTypes: ['test', 'live'],
      isLoading: false,
    }
  },
  validations: {
    formData: {
      type: {
        required,
      },
    },
  },
  computed: {},
  mounted() {
    this.setInitialData()
  },
  methods: {
    ...mapActions('emecef', ['loadMecef', 'editMecef']),

    checkType(){
      this.formData.url = this.formData.type == 'live'
            ? 'https://sygmef.impots.bj/emcf/api'
            : 'https://developper.impots.bj/sygmef-emcf/api'
    },

    async setInitialData() {
      let response = await this.loadMecef()
      this.formData.jeton = response.data.jeton
      this.formData.nim = response.data.nim
      this.formData.url = response.data.url
      this.formData.type = response.data.type
      this.formData.mdp = response.data.mdp
      this.formData.invoice_type = response.data.invoice_type
    },

    async updateEmecef() {
      this.$v.formData.$touch()
      if (this.$v.$invalid) {
        return true
      }
      this.isLoading = true
      let data = {
        jeton: this.formData.jeton,
        nim: this.formData.nim,
        url:
          this.formData.type == 'live'
            ? 'https://sygmef.impots.bj/emcf/api'
            : 'https://developper.impots.bj/sygmef-emcf/api',
        type: this.formData.type,
        invoice_type: this.formData.invoice_type,
        mdp: this.formData.mdp,
      }

      let response = await this.editMecef(data)
      if (response.data.success) {
        this.isLoading = false
        window.toastr['success'](this.$t('settings.emecef.updated_message'))
        return true
      }
      window.toastr['error'](response.data.error)
      return true
    },
  },
}
</script>
