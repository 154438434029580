import * as types from './mutation-types'

export const fetchSupply = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/supply`, { params }).then((response) => {
      commit(types.BOOTSTRAP_ITEMS, response.data.supply.data)
      commit(types.SET_TOTAL_ITEMS, response.data.supply.total)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const addSupply = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/supply', data).then((response) => {
      commit(types.ADD_ITEM, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateSupply = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/api/supply/${data.id}`, data).then((response) => {
      commit(types.UPDATE_ITEM, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteSupply = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete(`/api/supply/${id}`).then((response) => {
      commit(types.DELETE_ITEM, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteMultipleSupplys = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/supply/delete`, { 'id': state.selectedSupplys }).then((response) => {
      commit(types.DELETE_MULTIPLE_ITEMS, state.selectedSupplys)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllSupplys = ({ commit, dispatch, state }) => {
  if (state.selectedSupplys.length === state.items.length) {
    commit(types.SET_SELECTED_ITEMS, [])
    commit(types.SET_SELECT_ALL_STATE, false)
  } else {
    let allSupplyIds = state.items.map(item => item.id)
    commit(types.SET_SELECTED_ITEMS, allSupplyIds)
    commit(types.SET_SELECT_ALL_STATE, true)
  }
}

export const selectSupply = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECTED_ITEMS, data)
  if (state.selectedSupplys.length === state.items.length) {
    commit(types.SET_SELECT_ALL_STATE, true)
  } else {
    commit(types.SET_SELECT_ALL_STATE, false)
  }
}

export const addSupplyUnit = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/units`, data).then((response) => {
      commit(types.ADD_ITEM_UNIT, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateSupplyUnit = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/api/units/${data.id}`, data).then((response) => {
      commit(types.UPDATE_ITEM_UNIT, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fetchSupplyUnits = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/units`).then((response) => {
      commit(types.SET_ITEM_UNITS, response.data.units)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fatchSupplyUnit = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/units/${id}`).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteSupplyUnit = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete(`/api/units/${id}`).then((response) => {
      if (!response.data.error) {
        commit(types.DELETE_ITEM_UNIT, id)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
