<template>
  <div class="main-content item-create">
    <div class="page-header">
      <h3 class="page-title">
        {{ isEdit ? $t('items.edit_item') : $t('items.new_item') }}
      </h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/admin/dashboard">{{
            $t('general.home')
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/admin/items">{{
            $tc('items.item', 2)
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <a href="#">
            {{ isEdit ? $t('items.edit_item') : $t('items.new_item') }}</a
          >
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <form action="" @submit.prevent="submitItem">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-sm-12">
                  <label>{{ $t('items.type') }}</label
                  ><span class="text-danger"> *</span>
                  <base-select
                    v-model="formData.type"
                    :options="itemTypes"
                    :searchable="true"
                    placeholder="Choisir un type"
                    :show-labels="false"
                    :invalid="$v.formData.type.$error"
                    @change="$v.formData.type.$touch()"
                  >
                  </base-select>
                  <div v-if="$v.formData.type.$error">
                    <span v-if="!$v.formData.type.required" class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label class="control-label">{{ $t('items.name') }}</label
                  ><span class="text-danger"> *</span>
                  <base-input
                    v-model.trim="formData.name"
                    :invalid="$v.formData.name.$error"
                    @input="checkName()"
                    focus
                    type="text"
                    name="name"
                  />
                  <span v-if="nameError" class="text-danger">
                    {{ nameError }}
                  </span>
                  <div v-if="$v.formData.name.$error">
                    <span v-if="!$v.formData.name.required" class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                    <span
                      v-if="!$v.formData.name.minLength"
                      class="text-danger"
                    >
                      {{
                        $tc(
                          'validation.name_min_length',
                          $v.formData.name.$params.minLength.min,
                          { count: $v.formData.name.$params.minLength.min }
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label>{{ $t('items.price') }}</label
                  ><span class="text-danger"> *</span>
                  <div class="base-input">
                    <money
                      @change="checkPrice()"
                      :class="{ invalid: $v.formData.price.$error }"
                      v-model="price"
                      v-bind="defaultCurrencyForInput"
                      class="input-field"
                      :invalid="$v.formData.price.$error"
                      focus
                    />
                    <!-- <input v-bind="defaultCurrencyForInput" :class="{'invalid' : $v.formData.price.$error}" v-model="price" v-money="money" class="input-field"/> -->
                  </div>
                  <div v-if="$v.formData.price.$error">
                    <span v-if="!$v.formData.price.required" class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                    <span
                      v-if="!$v.formData.price.maxLength"
                      class="text-danger"
                      >{{ $t('validation.price_maxlength') }}</span
                    >
                    <span
                      v-if="!$v.formData.price.minValue"
                      class="text-danger"
                      >{{ $t('validation.price_minvalue') }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label class="control-label">{{
                    $t('items.reference')
                  }}</label
                  ><span class="text-danger"> *</span>
                  <base-input
                    v-model.trim="formData.reference"
                    :invalid="$v.formData.reference.$error"
                    @input="$v.formData.reference.$touch()"
                    focus
                    type="text"
                    name="reference"
                  />
                  <div v-if="$v.formData.reference.$error">
                    <span
                      v-if="!$v.formData.reference.required"
                      class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                    <span
                      v-if="!$v.formData.reference.minLength"
                      class="text-danger"
                    >
                      {{
                        $tc(
                          'validation.reference_min_length',
                          $v.formData.reference.$params.minLength.min,
                          { count: $v.formData.reference.$params.minLength.min }
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label>{{ $t('items.barcode') }}</label>
                  <base-input
                    v-model.trim="formData.barcode_path"
                    focus
                    type="text"
                    name="barcode_path"
                  />
                  <!-- <div v-if="$v.formData.barcode_path.$error">
                    <span v-if="!$v.formData.barcode_path.required" class="text-danger">{{ $t('validation.required') }} </span>
                    <span v-if="!$v.formData.barcode_path.minLength" class="text-danger">
                      {{ $tc('validation.barcode_path_min_length', $v.formData.barcode_path.$params.minLength.min, { count: $v.formData.barcode_path.$params.minLength.min }) }}
                    </span>
                  </div> -->
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label>{{ $t('items.group') }}</label
                  ><span class="text-danger"> *</span>
                  <base-select
                    v-model="formData.group"
                    :invalid="$v.formData.group.$error"
                    @change="$v.formData.group.$touch()"
                    focus
                    :options="itemGroups"
                    :searchable="true"
                    :show-labels="false"
                    :placeholder="$t('items.select_a_group')"
                    label="name"
                  >
                  </base-select>
                  <div v-if="$v.formData.group.$error">
                    <span v-if="!$v.formData.group.required" class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label>{{ $t('items.unit') }}</label
                  ><span class="text-danger"> *</span>
                  <base-select
                    v-model="formData.unit"
                    :options="itemUnits"
                    :disabled="formData.type == 'service'"
                    :searchable="true"
                    :show-labels="false"
                    :invalid="$v.formData.unit.$error"
                    @change="$v.formData.unit.$touch()"
                    :placeholder="$t('items.select_a_unit')"
                    label="name"
                  >
                    <div slot="afterList">
                      <button
                        type="button"
                        class="list-add-button"
                        @click="addItemUnit"
                      >
                        <font-awesome-icon class="icon" icon="cart-plus" />
                        <label>{{
                          $t('settings.customization.items.add_item_unit')
                        }}</label>
                      </button>
                    </div>
                  </base-select>
                  <div v-if="$v.formData.unit.$error">
                    <span v-if="!$v.formData.unit.required" class="text-danger"
                      >{{ $t('validation.required') }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- <div v-if="isTaxPerItem" class="form-group">
                <label>{{ $t('items.taxes') }}</label>
                <base-select
                  v-model="formData.taxes"
                  :options="getTaxTypes"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true"
                  :multiple="true"
                  track-by="tax_type_id"
                  label="tax_name"
                />
              </div> -->
              <div class="row">
                <div class="form-group col-sm-12">
                  <label for="description">{{ $t('items.description') }}</label>
                  <base-text-area
                    v-model="formData.description"
                    rows="2"
                    name="description"
                    @input="$v.formData.description.$touch()"
                  />
                  <div v-if="$v.formData.description.$error">
                    <span
                      v-if="!$v.formData.description.maxLength"
                      class="text-danger"
                    >
                      {{ $t('validation.description_maxlength') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <base-button
                  :loading="isLoading"
                  :disabled="isLoading"
                  icon="save"
                  color="theme"
                  type="submit"
                  class="collapse-button"
                >
                  {{ isEdit ? $t('items.update_item') : $t('items.save_item') }}
                </base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapActions, mapGetters } from 'vuex'
const {
  required,
  minLength,
  numeric,
  minValue,
  maxLength,
} = require('vuelidate/lib/validators')
import { helpers } from 'vuelidate/lib/validators'
// const alpha = helpers.regex('alpha', /^[a-zA-Z]*$/)
// const alphaNumValidator = helpers.regex('alphaNum', /^[a-z\d.]*$/i);

export default {
  mixins: {
    validationMixin,
  },
  data() {
    return {
      nameError: null,
      isLoading: false,
      title: 'Add Item',
      units: [],
      taxes: [],
      itemTypes: ['article', 'service'],
      itemGroups: [
        {
          name: 'A - Exonéré',
          value: 'A',
        },
        {
          name: 'B - Taxable',
          value: 'B',
        },
        {
          name: 'C - Exportation de produits taxables',
          value: 'C',
        },
        {
          name: 'D - TVA régime d’exception',
          value: 'D',
        },
        {
          name: 'E - Régime fiscal TPS',
          value: 'E',
        },
        {
          name: 'F - Réservé',
          value: 'F',
        },
      ],
      taxPerItem: '',
      formData: {
        name: '',
        barcode_path: '',
        reference: '',
        description: '',
        price: '',
        unit_id: null,
        unit: null,
        group: null,
        type: 'article',
        taxes: [],
        tax_per_item: false,
      },
      money: {
        decimal: '.',
        thousands: ',',
        suffix: ' XOF',
        precision: null,
        masked: false,
      },
    }
  },
  computed: {
    isRequired() {
      if (this.formData.type === 'article') {
        return false
      }
      return true
    },
    ...mapGetters('currency', ['defaultCurrencyForInput']),
    ...mapGetters('item', ['itemUnits']),
    price: {
      get: function () {
        return this.formData.price / 100
      },
      set: function (newValue) {
        this.formData.price = newValue * 100
      },
    },
    ...mapGetters('taxType', ['taxTypes']),
    isEdit() {
      if (this.$route.name === 'items.edit') {
        return true
      }
      return false
    },
    isTaxPerItem() {
      return this.taxPerItem === 'YES' ? 1 : 0
    },
    getTaxTypes() {
      return this.taxTypes.map((tax) => {
        return {
          ...tax,
          tax_type_id: tax.id,
          tax_name: tax.name + ' (' + tax.percent + '%)',
        }
      })
    },
  },
  created() {
    this.setTaxPerItem()
    if (this.isEdit) {
      this.loadEditData()
    }
  },
  validations: {
    formData: {
      type: {
        required,
      },
      group: {
        required,
      },
      unit: {
        // required,
      },
      name: {
        required,
        minLength: minLength(3),
        // alphaNumValidator,
      },
      // barcode_path: {
      //   required,
      //   minLength: minLength(2)
      // },
      reference: {
        required,
        minLength: minLength(2),
      },
      price: {
        required,
        numeric,
        maxLength: maxLength(20),
        minValue: minValue(0.1),
      },
      description: {
        maxLength: maxLength(255),
      },
    },
  },
  methods: {
    ...mapActions('item', ['addItem', 'fetchItem', 'updateItem']),
    ...mapActions('modal', ['openModal']),
    checkPrice() {
      console.log(this.price)
    },

    checkName() {
      this.$v.formData.name.$touch()
      let name = this.formData.name
      if (
        name.includes(';') ||
        name.includes(',') ||
        name.includes('&') ||
        name.includes('<') ||
        name.includes('>')
      ) {
        this.nameError =
          'Le nom du produit ne peut comporter ces caractères : &,<>; '
      } else {
        this.nameError = null
      }
    },
    async setTaxPerItem() {
      let res = await axios.get('/api/settings/get-setting?key=tax_per_item')
      if (res.data && res.data.tax_per_item === 'YES') {
        this.taxPerItem = 'YES'
      } else {
        this.taxPerItem = 'FALSE'
      }
    },
    async loadEditData() {
      let response = await this.fetchItem(this.$route.params.id)

      this.formData = { ...response.data.item, unit: null }
      this.formData.taxes = response.data.item.taxes.map((tax) => {
        return { ...tax, tax_name: tax.name + ' (' + tax.percent + '%)' }
      })

      this.formData.unit = this.itemUnits.find(
        (_unit) => response.data.item.unit_id === _unit.id
      )
      this.formData.group = this.itemGroups.find(
        (_group) => response.data.item.group === _group.value
      )
      this.fractional_price = response.data.item.price
    },
    async submitItem() {
      if (this.formData.type == 'article' && !this.formData.unit) {
        window.toastr['error'](
          "L'unité de mesure est obligatoire pour ce type de produit"
        )
        return false
      }

      let name = this.formData.name
      if (
        name.includes(';') ||
        name.includes(',') ||
        name.includes('&') ||
        name.includes('<') ||
        name.includes('>')
      ) {
        window.toastr['error'](
          'Le nom du produit ne peut comporter ces caractères : &,<>;'
        )
        return false
      }
      this.$v.formData.$touch()
      if (this.$v.$invalid) {
        return false
      }
      if (this.formData.unit) {
        this.formData.unit_id = this.formData.unit.id
      }
      if (this.formData.group) {
        this.formData.group = this.formData.group.value
      }
      let response
      this.isLoading = true
      if (this.isEdit) {
        response = await this.updateItem(this.formData).catch((err) => {
          this.isLoading = false
        })
      } else {
        let data = {
          ...this.formData,
          taxes: this.formData.taxes.map((tax) => {
            return {
              tax_type_id: tax.id,
              amount: (this.formData.price * tax.percent) / 100,
              percent: tax.percent,
              name: tax.name,
              collective_tax: 0,
            }
          }),
        }
        response = await this.addItem(data).catch((err) => {
          this.isLoading = false
        })
      }
      if (response.data) {
        this.isLoading = false
        window.toastr['success'](this.$tc('items.updated_message'))
        this.$router.push('/admin/items')
        return true
      }
      window.toastr['error'](response.data.error)
    },
    async addItemUnit() {
      this.openModal({
        title: this.$t('settings.customization.items.add_item_unit'),
        componentName: 'ItemUnit',
      })
    },
  },
}
</script>
