var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item-selector" },
    [
      _vm.item.item_id
        ? _c("div", { staticClass: "selected-item" }, [
            _vm._v("\n    " + _vm._s(_vm.item.name) + "\n\n    "),
            _c(
              "span",
              { staticClass: "deselect-icon", on: { click: _vm.deselectItem } },
              [_c("font-awesome-icon", { attrs: { icon: "times-circle" } })],
              1
            )
          ])
        : _c(
            "base-select",
            {
              ref: "baseSelect",
              staticClass: "multi-select-item",
              attrs: {
                options: _vm.items,
                loading: _vm.loading,
                "show-labels": false,
                "preserve-search": true,
                "initial-search": _vm.item.name,
                invalid: _vm.invalid,
                placeholder: _vm.$t("estimates.item.select_an_item"),
                label: "name"
              },
              on: { value: _vm.onTextChange, select: _vm.onSelect },
              model: {
                value: _vm.itemSelect,
                callback: function($$v) {
                  _vm.itemSelect = $$v
                },
                expression: "itemSelect"
              }
            },
            [
              _c("div", { attrs: { slot: "afterList" }, slot: "afterList" }, [
                _c(
                  "button",
                  {
                    staticClass: "list-add-button",
                    attrs: { type: "button" },
                    on: { click: _vm.openItemModal }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: "cart-plus" }
                    }),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("general.add_new_item")))
                    ])
                  ],
                  1
                )
              ])
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }