<template>
  <div class="invoice-index-page invoices main-content">
    <div class="page-header">
      <div class="d-flex flex-row">
        <div>
          <h3 class="page-title">{{ $tc('supply.supply', 2) }}</h3>
        </div>
      </div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t('general.home') }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc('supply.supply', 2) }}
          </router-link>
        </li>
      </ol>
      <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="totalSupply || filtersApplied"
            :outline="true"
            :icon="filterIcon"
            color="theme"
            size="large"
            right-icon
            @click="toggleFilter"
          >
            {{ $t('general.filter') }}
          </base-button>
        </div>
        <router-link slot="item-title" class="col-xs-2 mr-4" to="supply/create">
          <base-button color="theme" icon="plus" size="large">
            {{ $t('supply.add') }}
          </base-button>
        </router-link>
        <div class="col-xs-2">
          <download-excel
            size="large"
            disabled
            class="btn btn-default btn-lg"
            icon="file"
            color="theme"
            :data="fetchDataArray"
            :fields="json_fields"
            worksheet="Document"
            name="liste_appro.xls"
          >
            EXPORTER
          </download-excel>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="filter-container">
          <div class="filter-date">
            <div class="from pr-3">
              <label>{{ $t('general.from') }}</label>
              <base-date-picker
                v-model="filters.from_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
            <div class="dashed" />
            <div class="to pl-3">
              <label>{{ $t('general.to') }}</label>
              <base-date-picker
                v-model="filters.to_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
          </div>
        </div>
        <label class="clear-filter" @click="clearFilter">{{
          $t('general.clear_all')
        }}</label>
      </div>
    </transition>

    <div
      v-cloak
      v-show="showEmptyScreen"
      class="col-xs-1 no-data-info"
      align="center"
    >
      <satellite-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t('supply.no_items') }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t('supply.list_of_items')
        }}</label>
      </div>
      <div class="btn-container">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="$router.push('supply/create')"
        >
          {{ $t('supply.add_new_item') }}
        </base-button>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t('general.showing') }} <b>{{ supply.length }}</b>
          {{ $t('general.of') }} <b>{{ totalSupply }}</b>
        </p>
        <transition name="fade">
          <v-dropdown v-if="selectedSupply.length" :show-arrow="false">
            <span
              slot="activator"
              href="#"
              class="table-actions-button dropdown-toggle"
            >
              {{ $t('general.actions') }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="removeMultipleItems">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="dropdown-item-icon"
                />
                {{ $t('general.delete') }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

      <div class="custom-control custom-checkbox">
        <input
          id="select-all"
          v-model="selectAllFieldStatus"
          type="checkbox"
          class="custom-control-input"
          @change="selectAllSupply"
        />
        <label
          v-show="!isRequestOngoing"
          for="select-all"
          class="custom-control-label selectall"
        >
          <span class="select-all-label">{{ $t('general.select_all') }} </span>
        </label>
      </div>

      <table-component
        ref="table"
        :data="fetchData"
        :show-filter="false"
        table-class="table"
      >
        <!-- <table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <div class="custom-control custom-checkbox">
              <input
                :id="row.id"
                v-model="selectField"
                :value="row.id"
                type="checkbox"
                class="custom-control-input"
              />
              <label :for="row.id" class="custom-control-label" />
            </div>
          </template>
        </table-column> -->
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <div v-html="row.index" />
          </template>
        </table-column>
        <table-column :label="$t('supply.product')" show="item_id">
          <template slot-scope="row">
            <div v-html="row.item.name" />
          </template>
        </table-column>
        <table-column :label="$t('supply.unit')" show="unit" />
        <table-column :label="$t('supply.quantity')" show="quantity" />
        <table-column :label="$t('supply.price')" show="price">
          <template slot-scope="row">
            <span> {{ $t('supply.price') }} </span>
            <div v-html="$utils.formatMoney(row.price, defaultCurrency)" />
          </template>
        </table-column>
        <table-column
          :label="$t('supply.added_on')"
          sort-as="created_at"
          show="created_at"
        />
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DotIcon from '../../components/icon/DotIcon'
import SatelliteIcon from '../../components/icon/SatelliteIcon'
import BaseButton from '../../../js/components/base/BaseButton'
import moment from 'moment'

export default {
  components: {
    DotIcon,
    SatelliteIcon,
    BaseButton,
  },
  data() {
    return {
      id: null,
      showFilters: false,
      sortedBy: 'created_at',
      isRequestOngoing: true,
      filtersApplied: false,
      filters: {
        from_date: '',
        to_date: '',
      },
      fetchDataArray: [],
      json_fields: {
        'Nom du produit': 'item.name',
        Unité: 'unit',
        Quantité: 'quantity',
        'Prix unitaire': {
          field: 'price',
          callback: (value) => {
            return this.$utils.formatMoney(value)
          },
        },
        'Date de création': 'created_at',
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },
  computed: {
    ...mapGetters('supply', [
      'supply',
      'selectedSupply',
      'totalSupply',
      'selectAllField',
      'itemUnits',
    ]),
    ...mapGetters('currency', ['defaultCurrency']),
    showEmptyScreen() {
      return !this.totalSupply && !this.isRequestOngoing && !this.filtersApplied
    },
    filterIcon() {
      return this.showFilters ? 'times' : 'filter'
    },
    selectField: {
      get: function () {
        return this.selectedSupply
      },
      set: function (val) {
        this.selectItem(val)
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  destroyed() {
    if (this.selectAllField) {
      this.selectAllSupply()
    }
  },
  methods: {
    ...mapActions('supply', [
      'fetchSupply',
      'selectAllSupply',
      'selectItem',
      'deleteItem',
      'deleteMultipleSupply',
      'setSelectAllState',
    ]),
    refreshTable() {
      this.$refs.table.refresh()
    },
    async fetchData({ page, filter, sort }) {
      let data = {
        search: this.filters.name !== null ? this.filters.name : '',
        from_date:
          this.filters.from_date === ''
            ? this.filters.from_date
            : moment(this.filters.from_date).format('DD/MM/YYYY'),
        to_date:
          this.filters.to_date === ''
            ? this.filters.to_date
            : moment(this.filters.to_date).format('DD/MM/YYYY'),
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchSupply(data)
      this.isRequestOngoing = false

      this.fetchDataArray = response.data.supply.data
      return {
        data: response.data.supply.data,
        pagination: {
          totalPages: response.data.supply.last_page,
          currentPage: page,
        },
      }
    },
    setFilters() {
      this.filtersApplied = true
      this.refreshTable()
    },
    clearFilter() {
      this.filters = {
        from_date: '',
        to_date: '',
      }

      this.$nextTick(() => {
        this.filtersApplied = false
      })
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter()
        this.refreshTable()
      }

      this.showFilters = !this.showFilters
    },
    async removeItems(id) {
      this.id = id
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('supply.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteItem(this.id)
          if (res.data.success) {
            window.toastr['success'](this.$tc('supply.deleted_message', 1))
            this.$refs.table.refresh()
            return true
          }

          if (res.data.error === 'item_attached') {
            window.toastr['error'](
              this.$tc('supply.item_attached_message'),
              this.$t('general.action_failed')
            )
            return true
          }

          window.toastr['error'](res.data.message)
          return true
        }
      })
    },
    async removeMultipleItems() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('supply.confirm_delete', 2),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteMultipleItems()
          if (res.data.success || res.data.supply) {
            window.toastr['success'](this.$tc('supply.deleted_message', 2))
            this.$refs.table.refresh()
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },
  },
}
</script>
