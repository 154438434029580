import * as types from './mutation-types'

export default {
  [types.BOOTSTRAP_ITEMS] (state, items) {
    state.supply = items
  },

  [types.SET_TOTAL_ITEMS] (state, totalSupply) {
    state.totalSupply = totalSupply
  },

  [types.ADD_ITEM] (state, data) {
    state.supply.push(data.item)
  },

  [types.UPDATE_ITEM] (state, data) {
    let pos = state.supply.findIndex(item => item.id === data.item.id)

    state.supply[pos] = data.item
  },

  [types.DELETE_ITEM] (state, id) {
    let index = state.supply.findIndex(item => item.id === id)
    state.supply.splice(index, 1)
  },

  [types.DELETE_MULTIPLE_ITEMS] (state, selectedSupply) {
    selectedSupply.forEach((item) => {
      let index = state.supply.findIndex(_item => _item.id === item.id)
      state.supply.splice(index, 1)
    })

    state.selectedSupply = []
  },

  [types.SET_SELECTED_ITEMS] (state, data) {
    state.selectedSupply = data
  },

  [types.SET_SELECT_ALL_STATE] (state, data) {
    state.selectAllField = data
  },

  [types.ADD_ITEM_UNIT] (state, data) {
    state.itemUnits = [data.unit, ...state.itemUnits]
  },

  [types.SET_ITEM_UNITS] (state, data) {
    state.itemUnits = data
  },

  [types.DELETE_ITEM_UNIT] (state, id) {
    let index = state.itemUnits.findIndex(unit => unit.id === id)
    state.itemUnits.splice(index, 1)
  },

  [types.UPDATE_ITEM_UNIT] (state, data) {
    let pos = state.itemUnits.findIndex(unit => unit.id === data.unit.id)
    state.itemUnits.splice(pos, 1)
    state.itemUnits = [data.unit, ...state.itemUnits]
  }
}
