var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.isEdit ? _vm.$t("items.edit_item") : _vm.$t("items.new_item")
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/dashboard" },
                slot: "item-title"
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/items" },
                slot: "item-title"
              },
              [_vm._v(_vm._s(_vm.$tc("items.item", 2)))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("items.edit_item")
                    : _vm.$t("items.new_item")
                )
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitItem($event)
                }
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-12" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("items.type")))]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *")
                      ]),
                      _vm._v(" "),
                      _c("base-select", {
                        attrs: {
                          options: _vm.itemTypes,
                          searchable: true,
                          placeholder: "Choisir un type",
                          "show-labels": false,
                          invalid: _vm.$v.formData.type.$error
                        },
                        on: {
                          change: function($event) {
                            return _vm.$v.formData.type.$touch()
                          }
                        },
                        model: {
                          value: _vm.formData.type,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "type", $$v)
                          },
                          expression: "formData.type"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.type.$error
                        ? _c("div", [
                            !_vm.$v.formData.type.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("items.name")))
                      ]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *")
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          invalid: _vm.$v.formData.name.$error,
                          focus: "",
                          type: "text",
                          name: "name"
                        },
                        on: {
                          input: function($event) {
                            return _vm.checkName()
                          }
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.name"
                        }
                      }),
                      _vm._v(" "),
                      _vm.nameError
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.nameError) +
                                "\n                "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$v.formData.name.$error
                        ? _c("div", [
                            !_vm.$v.formData.name.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.formData.name.minLength
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$tc(
                                          "validation.name_min_length",
                                          _vm.$v.formData.name.$params.minLength
                                            .min,
                                          {
                                            count:
                                              _vm.$v.formData.name.$params
                                                .minLength.min
                                          }
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group col-sm-6" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("items.price")))]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "base-input" },
                      [
                        _c(
                          "money",
                          _vm._b(
                            {
                              staticClass: "input-field",
                              class: { invalid: _vm.$v.formData.price.$error },
                              attrs: {
                                invalid: _vm.$v.formData.price.$error,
                                focus: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.checkPrice()
                                }
                              },
                              model: {
                                value: _vm.price,
                                callback: function($$v) {
                                  _vm.price = $$v
                                },
                                expression: "price"
                              }
                            },
                            "money",
                            _vm.defaultCurrencyForInput,
                            false
                          )
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$v.formData.price.$error
                      ? _c("div", [
                          !_vm.$v.formData.price.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.required")) +
                                    "\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.price.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.price_maxlength"))
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.price.minValue
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.price_minvalue"))
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("items.reference")))
                      ]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *")
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          invalid: _vm.$v.formData.reference.$error,
                          focus: "",
                          type: "text",
                          name: "reference"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.formData.reference.$touch()
                          }
                        },
                        model: {
                          value: _vm.formData.reference,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              "reference",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.reference"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.reference.$error
                        ? _c("div", [
                            !_vm.$v.formData.reference.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.formData.reference.minLength
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$tc(
                                          "validation.reference_min_length",
                                          _vm.$v.formData.reference.$params
                                            .minLength.min,
                                          {
                                            count:
                                              _vm.$v.formData.reference.$params
                                                .minLength.min
                                          }
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("items.barcode")))]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          focus: "",
                          type: "text",
                          name: "barcode_path"
                        },
                        model: {
                          value: _vm.formData.barcode_path,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              "barcode_path",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.barcode_path"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("items.group")))]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *")
                      ]),
                      _vm._v(" "),
                      _c("base-select", {
                        attrs: {
                          invalid: _vm.$v.formData.group.$error,
                          focus: "",
                          options: _vm.itemGroups,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("items.select_a_group"),
                          label: "name"
                        },
                        on: {
                          change: function($event) {
                            return _vm.$v.formData.group.$touch()
                          }
                        },
                        model: {
                          value: _vm.formData.group,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "group", $$v)
                          },
                          expression: "formData.group"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.group.$error
                        ? _c("div", [
                            !_vm.$v.formData.group.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("items.unit")))]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *")
                      ]),
                      _vm._v(" "),
                      _c(
                        "base-select",
                        {
                          attrs: {
                            options: _vm.itemUnits,
                            disabled: _vm.formData.type == "service",
                            searchable: true,
                            "show-labels": false,
                            invalid: _vm.$v.formData.unit.$error,
                            placeholder: _vm.$t("items.select_a_unit"),
                            label: "name"
                          },
                          on: {
                            change: function($event) {
                              return _vm.$v.formData.unit.$touch()
                            }
                          },
                          model: {
                            value: _vm.formData.unit,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "unit", $$v)
                            },
                            expression: "formData.unit"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "afterList" }, slot: "afterList" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "list-add-button",
                                  attrs: { type: "button" },
                                  on: { click: _vm.addItemUnit }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon",
                                    attrs: { icon: "cart-plus" }
                                  }),
                                  _vm._v(" "),
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "settings.customization.items.add_item_unit"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$v.formData.unit.$error
                        ? _c("div", [
                            !_vm.$v.formData.unit.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-12" },
                    [
                      _c("label", { attrs: { for: "description" } }, [
                        _vm._v(_vm._s(_vm.$t("items.description")))
                      ]),
                      _vm._v(" "),
                      _c("base-text-area", {
                        attrs: { rows: "2", name: "description" },
                        on: {
                          input: function($event) {
                            return _vm.$v.formData.description.$touch()
                          }
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.description.$error
                        ? _c("div", [
                            !_vm.$v.formData.description.maxLength
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "validation.description_maxlength"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "collapse-button",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          icon: "save",
                          color: "theme",
                          type: "submit"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("items.update_item")
                                : _vm.$t("items.save_item")
                            ) +
                            "\n              "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }