var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content dashboard", attrs: { id: "app" } },
    [
      _vm.userRole == "caissière" || _vm.userRole == "admin"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "dash-item col-sm-6" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/invoices" },
                    slot: "item-title"
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.getTotalDueAmount,
                                      _vm.defaultCurrency
                                    )
                                  )
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.due_amount")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("dollar-icon", { staticClass: "card-icon" })],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-sm-6" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/customers" },
                    slot: "item-title"
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getContacts) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.customers")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("contact-icon", { staticClass: "card-icon" })],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-sm-6" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/invoices" },
                    slot: "item-title"
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getInvoices) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.invoices")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("invoice-icon", { staticClass: "card-icon" })],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-sm-6" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/estimates" },
                    slot: "item-title"
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getEstimates) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.estimates")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("estimate-icon", { staticClass: "card-icon" })],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.userRole == "controlleur"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "dash-item col-sm-6" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/customers" },
                    slot: "item-title"
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getContacts) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.customers")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("contact-icon", { staticClass: "card-icon" })],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-sm-6" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/items" },
                    slot: "item-title"
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getItems) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.items")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("invoice-icon", { staticClass: "card-icon" })],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-sm-6" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/supply" },
                    slot: "item-title"
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getSupply) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.supply")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("estimate-icon", { staticClass: "card-icon" })],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.userRole == "caissière" || _vm.userRole == "admin"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12 mt-2" }, [
              _c("div", { staticClass: "card dashboard-card" }, [
                _c("div", { staticClass: "graph-body" }, [
                  _c(
                    "div",
                    { staticClass: "card-body col-md-12 col-lg-12 col-xl-10" },
                    [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h6", [
                          _c("i", {
                            staticClass: "fa fa-line-chart text-primary"
                          }),
                          _vm._v(
                            _vm._s(_vm.$t("dashboard.monthly_chart.title")) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "year-selector" },
                          [
                            _c("base-select", {
                              attrs: {
                                options: _vm.years,
                                "allow-empty": false,
                                "show-labels": false,
                                placeholder: _vm.$t("dashboard.select_year")
                              },
                              model: {
                                value: _vm.selectedYear,
                                callback: function($$v) {
                                  _vm.selectedYear = $$v
                                },
                                expression: "selectedYear"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _vm.isLoaded
                        ? _c("line-chart", {
                            attrs: {
                              "format-money": _vm.$utils.formatMoney,
                              "format-graph-money": _vm.$utils.formatGraphMoney,
                              invoices: _vm.getChartInvoices,
                              receipts: _vm.getReceiptTotals,
                              income: _vm.getNetProfits,
                              labels: _vm.getChartMonths
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLoaded
                        ? _c("line-chart", {
                            staticClass: "mt-5",
                            attrs: {
                              "format-money": _vm.$utils.formatMoney,
                              "format-graph-money": _vm.$utils.formatGraphMoney,
                              expenses: _vm.getChartExpenses,
                              labels: _vm.getChartMonths
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-desc col-md-12 col-lg-12 col-xl-2" },
                    [
                      _c("div", { staticClass: "stats" }, [
                        _c("div", { staticClass: "description" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("dashboard.chart_info.total_sales")
                                ) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm.isLoaded
                            ? _c("span", { staticClass: "total" }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        _vm.getTotalSales,
                                        _vm.defaultCurrency
                                      )
                                    )
                                  }
                                })
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("dashboard.chart_info.total_receipts")
                                ) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm.isLoaded
                            ? _c(
                                "span",
                                {
                                  staticClass: "total",
                                  staticStyle: { color: "#00c99c" }
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.getTotalReceipts,
                                          _vm.defaultCurrency
                                        )
                                      )
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("dashboard.chart_info.total_expense")
                                ) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm.isLoaded
                            ? _c(
                                "span",
                                {
                                  staticClass: "total",
                                  staticStyle: { color: "#fb7178" }
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.getTotalExpenses,
                                          _vm.defaultCurrency
                                        )
                                      )
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("dashboard.chart_info.net_income")
                                ) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "total",
                              staticStyle: { color: "#6aca25" }
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.getNetProfit,
                                      _vm.defaultCurrency
                                    )
                                  )
                                }
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.getLoadedData ? _c("base-loader") : _vm._e(),
      _vm._v(" "),
      _vm.userRole == "caissière" || _vm.userRole == "admin"
        ? _c("div", { staticClass: "row table-row" }, [
            _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
              _c(
                "div",
                { staticClass: "table-header" },
                [
                  _c("h6", { staticClass: "table-title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.recent_invoices_card.title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/invoices" } },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "btn-sm",
                          attrs: { outline: true, color: "theme" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "dashboard.recent_invoices_card.view_all"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dashboard-table" },
                [
                  _c(
                    "table-component",
                    {
                      ref: "inv_table",
                      staticClass: "dashboard",
                      attrs: {
                        data: _vm.getDueInvoices,
                        "show-filter": false,
                        "table-class": "table"
                      }
                    },
                    [
                      _c("table-column", {
                        attrs: {
                          label: _vm.$t(
                            "dashboard.recent_invoices_card.due_on"
                          ),
                          show: "formattedDueDate"
                        }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          label: _vm.$t(
                            "dashboard.recent_invoices_card.customer"
                          ),
                          show: "user.name"
                        }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          label: _vm.$t(
                            "dashboard.recent_invoices_card.amount_due"
                          ),
                          show: "due_amount",
                          "sort-as": "due_amount"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.recent_invoices_card.amount_due"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.due_amount,
                                          row.user.currency
                                        )
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1418339153
                        )
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class":
                            "action-dropdown dashboard-recent-invoice-options no-click"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(row) {
                                return [
                                  _c(
                                    "v-dropdown",
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            slot: "activator",
                                            href: "#/"
                                          },
                                          slot: "activator"
                                        },
                                        [_c("dot-icon")],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-dropdown-item",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: {
                                                to: {
                                                  path:
                                                    "invoices/" +
                                                    row.id +
                                                    "/view"
                                                }
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "dropdown-item-icon",
                                                attrs: { icon: "eye" }
                                              }),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("invoices.view")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1003377075
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2 mob-table" }, [
              _c(
                "div",
                { staticClass: "table-header" },
                [
                  _c("h6", { staticClass: "table-title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.recent_estimate_card.title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/estimates" } },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "btn-sm",
                          attrs: { outline: true, color: "theme" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "dashboard.recent_estimate_card.view_all"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dashboard-table" },
                [
                  _c(
                    "table-component",
                    {
                      ref: "est_table",
                      attrs: {
                        data: _vm.getRecentEstimates,
                        "show-filter": false,
                        "table-class": "table"
                      }
                    },
                    [
                      _c("table-column", {
                        attrs: {
                          label: _vm.$t("dashboard.recent_estimate_card.date"),
                          show: "formattedExpiryDate"
                        }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          label: _vm.$t(
                            "dashboard.recent_estimate_card.customer"
                          ),
                          show: "user.name"
                        }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          label: _vm.$t(
                            "dashboard.recent_estimate_card.amount_due"
                          ),
                          show: "total",
                          "sort-as": "total"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.recent_estimate_card.amount_due"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.total,
                                          row.user.currency
                                        )
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1388988000
                        )
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown no-click"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(row) {
                                return [
                                  _c(
                                    "v-dropdown",
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            slot: "activator",
                                            href: "#/"
                                          },
                                          slot: "activator"
                                        },
                                        [_c("dot-icon")],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-dropdown-item",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: {
                                                to: {
                                                  path:
                                                    "estimates/" +
                                                    row.id +
                                                    "/edit"
                                                }
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "dropdown-item-icon",
                                                attrs: {
                                                  icon: ["fas", "pencil-alt"]
                                                }
                                              }),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("general.edit")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-dropdown-item", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeEstimate(
                                                  row.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: ["fas", "trash"] }
                                            }),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t("general.delete")
                                                ) +
                                                "\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-dropdown-item",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: {
                                                to: {
                                                  path:
                                                    "estimates/" +
                                                    row.id +
                                                    "/view"
                                                }
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "dropdown-item-icon",
                                                attrs: { icon: "eye" }
                                              }),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("general.view")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2303995960
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.userRole == "controlleur"
        ? _c("div", { staticClass: "row table-row" }, [
            _c("div", { staticClass: "col-lg-12 col-xl-12 mt-2 mob-table" }, [
              _c(
                "div",
                { staticClass: "table-header" },
                [
                  _c("h6", { staticClass: "table-title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.recent_articles_card.title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/items" } },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "btn-sm",
                          attrs: { outline: true, color: "theme" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "dashboard.recent_articles_card.view_all"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dashboard-table" },
                [
                  _c(
                    "table-component",
                    {
                      ref: "est_table",
                      attrs: {
                        data: _vm.getRecentItems,
                        "show-filter": false,
                        "table-class": "table"
                      }
                    },
                    [
                      _c("table-column", {
                        attrs: { label: _vm.$t("items.name"), show: "name" }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: { label: _vm.$t("items.group"), show: "group" }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          label: _vm.$t("items.unit"),
                          show: "unit.name"
                        }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: { label: _vm.$t("items.stock"), show: "stock" }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          label: _vm.$t("items.price"),
                          show: "price",
                          "sort-as": "price"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("items.price")))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(row.price)
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4191135952
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }