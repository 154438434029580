var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-main-container customization" }, [
    _c(
      "div",
      { staticClass: "card setting-card" },
      [
        _c("ul", { staticClass: "tabs" }, [
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function($event) {
                  return _vm.setActiveTab("INVOICES")
                }
              }
            },
            [
              _c(
                "a",
                {
                  class: [
                    "tab-link",
                    { "a-active": _vm.activeTab === "INVOICES" }
                  ],
                  attrs: { href: "#" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("settings.customization.invoices.title"))
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function($event) {
                  return _vm.setActiveTab("ESTIMATES")
                }
              }
            },
            [
              _c(
                "a",
                {
                  class: [
                    "tab-link",
                    { "a-active": _vm.activeTab === "ESTIMATES" }
                  ],
                  attrs: { href: "#" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("settings.customization.estimates.title"))
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function($event) {
                  return _vm.setActiveTab("ITEMS")
                }
              }
            },
            [
              _c(
                "a",
                {
                  class: [
                    "tab-link",
                    { "a-active": _vm.activeTab === "ITEMS" }
                  ],
                  attrs: { href: "#" }
                },
                [_vm._v(_vm._s(_vm.$t("settings.customization.items.title")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-customize" } }, [
          _vm.activeTab === "INVOICES"
            ? _c("div", { staticClass: "invoice-tab" }, [
                _c(
                  "form",
                  {
                    staticClass: "mt-3",
                    attrs: { action: "" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.updateInvoiceSetting($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 mb-4" },
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.invoices.invoice_prefix"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            staticClass: "prefix-input",
                            attrs: {
                              invalid: _vm.$v.invoices.invoice_prefix.$error
                            },
                            on: {
                              input: function($event) {
                                return _vm.$v.invoices.invoice_prefix.$touch()
                              },
                              keyup: function($event) {
                                return _vm.changeToUppercase("INVOICES")
                              }
                            },
                            model: {
                              value: _vm.invoices.invoice_prefix,
                              callback: function($$v) {
                                _vm.$set(_vm.invoices, "invoice_prefix", $$v)
                              },
                              expression: "invoices.invoice_prefix"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.$v.invoices.invoice_prefix
                                    .required,
                                  expression:
                                    "!$v.invoices.invoice_prefix.required"
                                }
                              ],
                              staticClass: "text-danger mt-1"
                            },
                            [_vm._v(_vm._s(_vm.$t("validation.required")))]
                          ),
                          _vm._v(" "),
                          !_vm.$v.invoices.invoice_prefix.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.prefix_maxlength"))
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.invoices.invoice_prefix.alpha
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.characters_only"))
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row pb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                icon: "save",
                                color: "theme",
                                type: "submit"
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-customize" } }, [
          _vm.activeTab === "ESTIMATES"
            ? _c("div", { staticClass: "estimate-tab" }, [
                _c(
                  "form",
                  {
                    staticClass: "mt-3",
                    attrs: { action: "" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.updateEstimateSetting($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 mb-4" },
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.estimates.estimate_prefix"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            staticClass: "prefix-input",
                            attrs: {
                              invalid: _vm.$v.estimates.estimate_prefix.$error
                            },
                            on: {
                              input: function($event) {
                                return _vm.$v.estimates.estimate_prefix.$touch()
                              },
                              keyup: function($event) {
                                return _vm.changeToUppercase("ESTIMATES")
                              }
                            },
                            model: {
                              value: _vm.estimates.estimate_prefix,
                              callback: function($$v) {
                                _vm.$set(_vm.estimates, "estimate_prefix", $$v)
                              },
                              expression: "estimates.estimate_prefix"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.$v.estimates.estimate_prefix
                                    .required,
                                  expression:
                                    "!$v.estimates.estimate_prefix.required"
                                }
                              ],
                              staticClass: "text-danger mt-1"
                            },
                            [_vm._v(_vm._s(_vm.$t("validation.required")))]
                          ),
                          _vm._v(" "),
                          !_vm.$v.estimates.estimate_prefix.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.prefix_maxlength"))
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.estimates.estimate_prefix.alpha
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.characters_only"))
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row pb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                icon: "save",
                                color: "theme",
                                type: "submit"
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-customize" } }, [
          _vm.activeTab === "PAYMENTS"
            ? _c(
                "div",
                { staticClass: "payment-tab" },
                [
                  _c("div", { staticClass: "page-header" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-8" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-flex flex-row-reverse" },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "add-new-tax",
                              attrs: { outline: "", color: "theme" },
                              on: { click: _vm.addPaymentMode }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.payments.add_payment_mode"
                                    )
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "table-component",
                    {
                      ref: "table",
                      staticClass: "mb-3",
                      attrs: {
                        "show-filter": false,
                        data: _vm.paymentModes,
                        "table-class": "table tax-table"
                      }
                    },
                    [
                      _c("table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "settings.customization.payments.payment_mode"
                          ),
                          show: "name"
                        }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("settings.tax_types.action")
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown",
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            slot: "activator",
                                            href: "#"
                                          },
                                          slot: "activator"
                                        },
                                        [_c("dot-icon")],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-dropdown-item", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.editPaymentMode(row)
                                              }
                                            }
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: {
                                                icon: ["fas", "pencil-alt"]
                                              }
                                            }),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.$t("general.edit")) +
                                                "\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-dropdown-item", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.removePaymentMode(
                                                  row.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: ["fas", "trash"] }
                                            }),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t("general.delete")
                                                ) +
                                                "\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1129365639
                        )
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "pt-3",
                      attrs: { action: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.updatePaymentSetting($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12 mb-4" },
                          [
                            _c("label", { staticClass: "input-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "settings.customization.payments.payment_prefix"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("base-input", {
                              staticClass: "prefix-input",
                              attrs: {
                                invalid: _vm.$v.payments.payment_prefix.$error
                              },
                              on: {
                                input: function($event) {
                                  return _vm.$v.payments.payment_prefix.$touch()
                                },
                                keyup: function($event) {
                                  return _vm.changeToUppercase("PAYMENTS")
                                }
                              },
                              model: {
                                value: _vm.payments.payment_prefix,
                                callback: function($$v) {
                                  _vm.$set(_vm.payments, "payment_prefix", $$v)
                                },
                                expression: "payments.payment_prefix"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.$v.payments.payment_prefix
                                      .required,
                                    expression:
                                      "!$v.payments.payment_prefix.required"
                                  }
                                ],
                                staticClass: "text-danger mt-1"
                              },
                              [_vm._v(_vm._s(_vm.$t("validation.required")))]
                            ),
                            _vm._v(" "),
                            !_vm.$v.payments.payment_prefix.maxLength
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("validation.prefix_maxlength")
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.payments.payment_prefix.alpha
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.characters_only"))
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row pb-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(
                              "base-button",
                              {
                                attrs: {
                                  icon: "save",
                                  color: "theme",
                                  type: "submit"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("settings.customization.save")
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-customize" } }, [
          _vm.activeTab === "ITEMS"
            ? _c(
                "div",
                { staticClass: "item-tab" },
                [
                  _c("div", { staticClass: "page-header" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-8" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-flex flex-row-reverse" },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "add-new-tax",
                              attrs: { outline: "", color: "theme" },
                              on: { click: _vm.addItemUnit }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.items.add_item_unit"
                                    )
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "table-component",
                    {
                      ref: "itemTable",
                      staticClass: "mb-3",
                      attrs: {
                        "show-filter": false,
                        data: _vm.itemUnits,
                        "table-class": "table tax-table"
                      }
                    },
                    [
                      _c("table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("settings.customization.items.units"),
                          show: "name"
                        }
                      }),
                      _vm._v(" "),
                      _c("table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("settings.tax_types.action")
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown",
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            slot: "activator",
                                            href: "#"
                                          },
                                          slot: "activator"
                                        },
                                        [_c("dot-icon")],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-dropdown-item", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.editItemUnit(row)
                                              }
                                            }
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: {
                                                icon: ["fas", "pencil-alt"]
                                              }
                                            }),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.$t("general.edit")) +
                                                "\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-dropdown-item", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeItemUnit(
                                                  row.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: ["fas", "trash"] }
                                            }),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t("general.delete")
                                                ) +
                                                "\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          293055495
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }